import store from '@/store'

const usePermission = () => {
	let _permission = {}
	const updateUserStorage = data => {
		const {
			id,
			account,
			name,
			phone,
			email,
			loginTime,
			buyerType,
			loginType,
			permissionList,
			storeList,
			manager,
			superManager,
			notManager,
			sales,
		} = data

		store.commit('storage/setToken', loginType === 'BACK')
		store.commit('storage/setBuyerType', buyerType)
		store.commit('storage/setUserId', id)
		store.commit('storage/setAccount', account)
		store.commit('storage/setStoreId', storeList[0]?.id)
		const storeName = storeList[0]?.name
		store.commit('storage/setStoreName', storeName)
		document.title = storeName ? storeName : '點溡點餐平台'
		store.commit('storage/setPermissions', permissionList)
		store.commit('storage/setLoginStoreId', storeList[0]?.id)
		store.commit('storage/setManager', manager || sales)
		store.commit('storage/setSuper', superManager)
	}

	const loginRole = () => {
		let permissions = store.state.storage.permissions
		if (permissions.find(v => v === 'USER_SUPER_MANAGER')) {
			store.commit('storage/setLoginRole', 'USER_MANAGER')
			store.commit('storage/setSwitchRole', 'USER_MANAGER')
		} else if (permissions.find(v => v === 'USER_MANAGER')) {
			store.commit('storage/setLoginRole', 'USER_MANAGER')
			store.commit('storage/setSwitchRole', 'USER_MANAGER')
		} else if (permissions.find(v => v === 'USER_SALES_PERSON')) {
			store.commit('storage/setLoginRole', 'USER_SALES_PERSON')
			store.commit('storage/setSwitchRole', 'USER_SALES_PERSON')
		} else if (permissions.find(v => v === 'USER_STORE')) {
			store.commit('storage/setLoginRole', 'USER_STORE')
			store.commit('storage/setSwitchRole', 'USER_STORE')
		}
	}

	return {
		set() {
			const storagePermission = store.state.storage.permissions
			if (storagePermission) {
				const permissionArr = storagePermission
				_permission = {}
				permissionArr.forEach(e => {
					_permission[e] = true
				})
			}
		},
		get() {
			return _permission
		},
		/**
		 * 檢查是否有權限
		 * @param {*} key string 權限名稱
		 */
		is(key) {
			return _permission[key] || false
		},
		every(...keys) {
			return keys.every(e => _permission[e])
		},
		some(...keys) {
			return keys.some(e => _permission[e])
		},
		updateUserStorage,
		loginRole,
	}
}

export default Vue => {
	Vue.prototype.$permission = usePermission()
}
