import service from './service'

export default {
	// 暫時移除該功能
	//取得會員(多筆)
	// getList: (formdata) => service.post(`user/getUserList`, formdata),
	//取得會員(單筆)
	// getUser: (formdata) => service.get(`user/getUser?id=${formdata}`),
	//修改會員
	// updateUser: (formdata) => service.put(`user/updateUser`, formdata),
}
