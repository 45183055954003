import axios from 'axios'
import store from '@/store'
import storage from '@/store/storage'

const service = axios.create({
	baseURL: `${process.env.VUE_APP_BASE_API}/api`,
	timeout: 60000,
})

service.interceptors.request.use(
	config => {
		if (localStorage.adminVuex) {
			const vuex = JSON.parse(localStorage.adminVuex)
			const token = vuex.storage.xsrfToken
			if (token !== '') {
				config.headers['Content-Type'] = 'application/json'
				config.headers['X-CSRF-TOKEN'] = token
			}
			config.headers['X-Back-Stage-Version'] = store.state.version

			const androidVersion = storage.state.androidVersion
			if (androidVersion) {
				config.headers['X-Android-Version'] = androidVersion
			}

			const iosVersion = storage.state.iosVersion
			if (iosVersion) {
				config.headers['X-Ios-Version'] = iosVersion
			}
		}
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

service.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		if (error.message === 'Network Error' || error.code === 'ECONNABORTED') {
			return Promise.reject('網絡問題，請檢查您的網絡連接')
		}
		const res = error.response
		if (!res) {
			return Promise.reject('無法獲取伺服器回應')
		}

		if (res.status === 503) {
			const { title, message } = res.data.data
			store.commit(
				'setMaintenance',
				{
					modal: true,
					title: title,
					message: message,
				},
				{ root: true },
			)
			return Promise.reject('服務更新中')
		} else {
			store.commit(
				'setMaintenance',
				{
					modal: false,
					title: '',
					message: '',
				},
				{ root: true },
			)
		}

		if (res.status === 401 && res.config.url !== 'logout') {
			return store.dispatch('logout', false).then(() => {
				return Promise.reject('請登入後再使用')
			})
		} else if (res.status === 429) {
			return Promise.reject('操作過於頻繁')
		} else {
			return Promise.reject(
				res.data.message ? res.data.message : '服務異常，請聯繫相關人員',
			)
		}
	},
)

export default service
