<template>
	<MyFormRow
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:labelPosition="labelPosition"
		:color="color"
	>
		<v-autocomplete
			:value="value"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:no-data-text="noDataText"
			:disabled="disabled"
			:rules="rules"
			:filter="customFilter"
			outlined
			dense
			single-line
			:clearable="clearable"
			:placeholder="placeholder"
			@change="onChange"
			@click:clear="onClear"
			:menu-props="{ bottom: true, overflowY: true }"
		></v-autocomplete>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyAutocompleteSelect',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String | Number | Boolean,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		placeholder: {
			required: false,
			type: String,
			default: '',
		},
		itemText: {
			required: false,
			type: String,
			default: 'text',
		},
		itemValue: {
			required: false,
			type: String,
			default: 'value',
		},
		items: {
			required: false,
			type: Array,
			default: () => [],
		},
		noDataText: {
			required: false,
			type: String,
			default: '暫無數據',
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		clearable: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		customFilter(item, queryText, itemText) {
			if (queryText) {
				const number = parseInt(queryText)
				if (isNaN(number)) {
					return itemText.indexOf(queryText) > -1
				}
				let matchItem = this.items.find(i => i.id === number)
				return matchItem && matchItem.name === itemText
			}
			return true
		},
		onChange(val) {
			this.$emit('updateValue', val)
			this.$emit('onChange', val)
		},
		onClear(ev) {
			this.$emit('onClear', ev)
		},
	},
	// methods: {},
}
</script>
<style lang="scss" scoped>
// @import '@/style/'
::v-deep {
	.v-select__slot {
		display: flex;
		align-items: center;
		.v-input__append-inner {
			margin-top: 0.55rem !important;
		}
	}
	.v-select__selection {
		font-size: 1rem;
		height: 2rem;
		display: flex;
		align-items: center;
	}
	.v-input__append-inner {
		.mdi-close {
			background: $lightTextColor;
			border-radius: 50%;
			padding: 5px;
			&::before {
				color: $darkTextColor;
				font-size: 16px;
			}
		}
	}
}
</style>
