import service from './service'

export default {
	//取得所有業務
	getAll: () => service.get(`back/sales/getSimpleList`),
	//查看業務資料(多筆)
	getList: params => service.post(`back/sales/getPage`, params),
	//取得業務資料(單筆)
	getOne: params => service.get(`back/sales/getDetail?id=${params}`),
	//取得業務編號
	getNumber: () => service.get(`salesperson/get`),
	//查看店家紀錄
	getStore: () => service.get(`salesperson/getList`),
	//新增業務
	add: formdata =>
		service.post(`back/sales/add`, formdata, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),
	//編輯業務
	update: formdata =>
		service.put(`back/sales/update`, formdata, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),
	//刪除業務
	remove: params => service.delete(`/back/sales/remove?id=${params}`),
	// 取得搜尋所有業務(Excel)
	getAllBusinessWithExcel: params =>
		service.get(`back/sales/getExcelList`, { params }),
}
