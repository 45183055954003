<template>
	<div class="flex">
		<MyTimePicker v-model="times[0]" :min="startMin" />
		<div class="wave">~</div>
		<MyTimePicker v-model="times[1]" />
	</div>
</template>

<script>
import MyTimePicker from './TimePicker'
export default {
	name: 'MyTimeRangePicker',
	components: { MyTimePicker },
	props: {
		value: {
			type: Array,
			default: () => ['00:00', '00:00'],
		},
		startMin: {
			type: String,
		},
	},
	data() {
		return {
			times: this.value,
		}
	},
	watch: {
		value(e) {
			this.times = e
		},
		times(e) {
			this.$emit('input', e)
		},
	},
}
</script>

<style lang="scss" scoped>
.flex {
	display: flex;
	align-items: center;
}

.wave {
	margin-left: 8px;
	margin-right: 8px;
}
</style>
