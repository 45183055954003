<template>
	<MyFormRow
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:color="color"
		:labelPosition="labelPosition"
	>
		<v-text-field
			:clearable="clearable"
			:value="value"
			:rules="rules"
			:disabled="disabled"
			:readonly="readonly"
			:append-icon="isPassword ? (show ? 'mdi-eye' : 'mdi-eye-off') : null"
			outlined
			dense
			single-line
			:class="{ 'password-input': isPassword }"
			:type="isPassword ? (show ? 'text' : type) : type"
			@click:append="isPassword ? (show = !show) : null"
			@input="onInput"
			@keydown.enter="onEnter"
			:placeholder="placeholder"
			:error="error"
			:error-messages="!errorMessages ? [] : errorMessages"
			:append-outer-icon="appendOuterIcon ? appendOuterIcon : null"
			:inputmode="inputmode"
			:enterkeyhint="enterkeyhint"
			:autofocus="autofocus"
			:suffix="suffix"
			:min=min
			ref="ref"
			:hint="hint"
			persistent-hint
		></v-text-field>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyTextField',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String | Number,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		type: {
			required: false,
			type: String,
			default: 'text',
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		readonly: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		placeholder: {
			required: false,
			type: String,
		},
		error: {
			required: false,
			type: Boolean,
			default: false,
		},
		errorMessages: {
			required: false,
		},
		appendOuterIcon: {
			required: false,
		},
		inputmode: {
			required: false,
		},
		enterkeyhint: {
			required: false,
			type: String,
			default: 'none',
		},
		autofocus: { required: false, type: Boolean, default: false },
		search: {
			required: false,
			type: Boolean,
		},
		suffix: {
			required: false,
			type: String,
			default: '',
		},
		hint: {
			required: false,
			type: String,
		},
		clearable: {
			required: false,
			type: Boolean,
		},
		min: {
			required: false,
			type: Number,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	data() {
		return {
			show: false,
		}
	},
	computed: {
		validate() {
			return this.rules.length > 0
		},
		isPassword() {
			return this.type === 'password'
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onInput(val) {
			this.$emit('updateValue', val)
			this.$emit('onInput', val)
		},
		onEnter() {
			const { value } = this
			this.$emit('updateValue', value)
			this.$emit('onEnter', value)
		},
		focus() {
			this.$refs.ref?.focus()
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.v-text-field__slot {
		input {
			font-size: 1rem;
		}
	}
}
</style>
