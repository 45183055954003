<template>
	<div class="row-title"><slot></slot></div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyRowTitle',
	// components: { COMPONENT_NAME },
	// props: {},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.row-title {
	font-weight: 900;
	color: #212121;
	font-size: 1rem;
}
</style>
