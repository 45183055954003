<template>
	<div class="strengthBox">
		<div class="strength">
			<span :class="'red'"></span>
			<span :class="'orange'"></span>
			<span :class="'green'"></span>
			<p
				class="displayText"
				:class="
					strengthText === 'weak'
						? 'redTxt'
						: strengthText === 'mid'
						? 'orangeTxt'
						: 'greenTxt'
				"
			>
				弱
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PasswordStrength',
	props: {
		password: {
			type: String,
			required: true,
		},
	},
	model: {
		prop: 'value',
		event: 'checkScore',
	},
	data() {
		return {
			strengthText: 'weak',
			strengthScore: 0, //密碼強度得分
		}
	},
	watch: {
		password(val) {
			let score = 0
			let lowerCase = 0 //小寫數量
			let upperCase = 0 //大寫數量
			let number = 0 //數字數量
			let symbol = 0 //符號數量

			val.split('').forEach(item => {
				let ascii = item.charCodeAt()
				if (ascii >= 48 && ascii <= 57) {
					//數字
					number++
				} else if (ascii >= 65 && ascii <= 90) {
					//大寫
					upperCase++
				} else if (ascii >= 97 && ascii <= 122) {
					//小寫
					lowerCase++
				} else if (ascii !== 13 && ascii !== 10 && ascii !== 32) {
					//符號 (不含回車/換行/空白)
					symbol++
				}
			})

			//字母得分統計
			if (
				(lowerCase > 0 && upperCase === 0) ||
				(lowerCase === 0 && upperCase > 0)
			) {
				score = score + 10
			} else if (lowerCase > 0 && upperCase > 0) {
				score = score + 20
			}
			//數字得分統計
			if (number > 0 && number <= 2) {
				score = score + 10
			} else if (number >= 3) {
				score = score + 20
			}
			//符號得分統計
			if (symbol === 1) {
				score = score + 10
			} else if (symbol >= 1) {
				score = score + 25
			}
			//獎勵得分統計
			if ((lowerCase > 0 || upperCase > 0) && number > 0 && symbol === 0) {
				score = score + 2
			} else if (
				((lowerCase > 0 && upperCase === 0) ||
					(lowerCase === 0 && upperCase > 0)) &&
				number > 0 &&
				symbol > 0
			) {
				score = score + 3
			} else if (lowerCase > 0 && upperCase > 0 && number > 0 && symbol > 0) {
				score = score + 5
			}
			//密碼長度得分統計
			if (val.length <= 4) {
				score = score + 5
			} else if (val.length >= 5 && val.length <= 7) {
				score = score + 10
			} else {
				score = score + 25
			}
			this.strengthScore = score
			if (this.strengthScore < 50) {
				this.$emit('checkScore', false)
			} else {
				this.$emit('checkScore', true)
			}
		},
		strengthScore(val) {
			let text = document.getElementsByClassName('displayText')
			if (val < 50) {
				text[0].innerHTML = '弱'
				this.strengthText = 'weak'
			} else if (val >= 50 && val < 70) {
				text[0].innerHTML = '中'
				this.strengthText = 'mid'
			} else {
				text[0].innerHTML = '強'
				this.strengthText = 'good'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.strengthBox {
	position: relative;
	display: flex;
	.strength {
		position: relative;
		transform: translateY(-7.5px);
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		& > span {
			background-color: #f3f3f3;
			width: calc((100% - 40px) / 3);
			height: 5px;
			border-radius: 100px;
			transition: all 100ms ease-in;
			&:nth-child(2) {
				margin: 0 5px;
			}
		}
		p {
			margin-left: 12px;
			font-size: 16px;
			margin-bottom: 0;
		}
		.red {
			background-color: #ff1d00;
		}
		.redTxt {
			color: #ff1d00;
		}
		.orange {
			background-color: #ff9100;
		}
		.orangeTxt {
			color: #ff9100;
		}
		.green {
			background-color: #3e9820;
		}
		.greenTxt {
			color: #3e9820;
		}
		.normal {
			background-color: #f3f3f3;
		}
	}
}
</style>
