var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`inline-form inline-form--${_vm.color} ${
		'inline-form--location-' + _vm.labelPosition
	}`},[(_vm.label.length > 0)?_c('div',{staticClass:"inline-form__label",class:{ 'inline-form__label--validate': _vm.validate },style:({
			width: _vm.labelWidth,
			minWidth: _vm.labelWidth,
			textAlign: _vm.labelAlign,
		})},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,55053276)},[_c('span',[_vm._v(_vm._s(_vm.tooltip.label))])]):_vm._e(),(_vm.requireDot)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"inline-form__input"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }