var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-header"},[_vm._l((_vm.items),function(e,i){return [_c('div',{key:i,staticClass:"tab-wrap"},[_c('router-link',{staticClass:"tab-link",class:{
							'tab-link--active':
								typeof _vm.value === 'string' &&
								(e.value === _vm.value ||
									(_vm.routeBeginsWith && e.value.startsWith(_vm.routeBeginsWith))),
						},attrs:{"to":e.path},nativeOn:{"click":function($event){return _vm.onRouteClick(e.value)}}},[_c('span',{class:{ last: i === _vm.items.length - 1 }},[_vm._v(_vm._s(e.label))])])],1),(!_vm.manager && _vm.showLogoutBtn && i === _vm.items.length - 1)?_c('div',{staticClass:"logout"},[_c('v-btn',{staticClass:"logout-btn",attrs:{"color":"selectColor","large":"","dark":""},on:{"click":function($event){_vm.logoutModal = true}}},[_vm._v(" 登出 ")]),_c('MyModal',{attrs:{"title":"","submitName":"是","cancelName":"否","btnSize":false,"persistent":""},on:{"onCancel":function($event){_vm.logoutModal = false},"onSubmit":_vm.onLogout},model:{value:(_vm.logoutModal),callback:function ($$v) {_vm.logoutModal=$$v},expression:"logoutModal"}},[_c('MyDeleteText',{attrs:{"title":"登出"}},[_vm._v("確定要登出嗎？")])],1)],1):_vm._e()]}),_c('div',{staticClass:"active-line"})],2),(_vm.secondItems.length > 0)?_c('div',{staticClass:"tab-second"},[_c('div',{staticClass:"tab-second-left"},[_vm._l((_vm.secondItems),function(e,i){return [(e.path)?_c('router-link',{staticClass:"tab-btn",class:{
							'tab-btn--active': e.path === _vm.value,
						},attrs:{"to":e.path}},[_c('span',{class:{ last: i === _vm.secondItems.length - 1 }},[_vm._v(_vm._s(e.label))])]):_c('div',{staticClass:"tab-btn",class:{
							'tab-btn--active': e.value === _vm.secondValue,
						},on:{"click":function($event){$event.stopPropagation();return _vm.onRouteClick(e.value)}}},[_c('span',{class:{ last: i === _vm.secondItems.length - 1 }},[_vm._v(_vm._s(e.label))])])]}),_vm._t("default")],2),_c('div',{staticClass:"tab-second-right"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }