import {list as $http} from "@/utils/service/api";

/**
 * 檢查手機載具格式，及透過三方確認是否存在：
 * 1.含/總共8碼
 * 2.第一碼必為『/』
 * 3.除了/，其餘七碼則由數字【0-9】、大寫英文【A-Z】與特殊符號【.】【-】【+】組成
 *
 * @param carrier 手機載具，型別需為String
 * @param storeId 店家ID，型別需為Integer
 */
export function checkPhoneCarrier(carrier, storeId) {
  const pattern = /^\/{1}[A-Z0-9+-.]{7}$/;
  if (!pattern.test(carrier)) {
    return Promise.resolve(false);
  }

  return new Promise((resolve, reject) => {
    $http.order
      .checkPhoneCarrier({
        storeId: storeId,
        phoneCarrier: carrier,
      })
      .then(res => {
        if (!res.status) {
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch(error => {
        this.$store.commit('showMessage', {
          text: error,
          color: 'error',
        });
        resolve(false);
      });
  });
}

