<template>
	<v-dialog :value="isShow" :max-width="550" persistent>
		<v-card class="announcement">
			<div class="announcement-title">公告</div>
			<div class="announcement-line" />
			<div class="announcement-body">
				<div class="announcement-body-content" v-if="announcementDisplay">
					<div v-html="announcementDisplay.content"></div>
				</div>
			</div>
			<div class="announcement-date" v-if="announcementDisplay">
				{{
					$formatDate(
						new Date(announcementDisplay.startTime),
						'yyyy-MM-dd HH:mm',
					)
				}}
				~
				{{
					$formatDate(new Date(announcementDisplay.endTime), 'yyyy-MM-dd HH:mm')
				}}
			</div>
			<div
				class="announcement-action"
				v-if="announcementDisplay && announcementDisplay.type === 'TEXT'"
			>
				<v-btn color="lineColor" class="mr-2" outlined large @click="noTime">
					知道了
				</v-btn>
				<v-btn color="com1Color" large @click="nextTime"> 再提醒 </v-btn>
			</div>
			<div
				class="announcement-action"
				v-if="announcementDisplay && announcementDisplay.type === 'CLEAR_CACHE'"
			>
				<v-btn color="lineColor" class="mr-2" outlined large @click="nextTime">
					再提醒
				</v-btn>
				<v-btn color="com1Color" large @click="clearCache"> 清除緩存 </v-btn>
			</div>
			<div
				class="announcement-action"
				v-if="announcementDisplay && announcementDisplay.type === 'APP_UPDATE'"
			>
				<v-btn color="com1Color" large @click="appUpdate"> 前往更新 </v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'MyAnnouncementDisplayModal',
	props: {
		label: {
			required: false,
			type: String,
			default: '記得填',
		},
		submitLoading: {
			required: false,
			type: Boolean,
		},
	},
	data() {
		return {
			isShow: false,
			num: 0,
			see: [],
		}
	},
	computed: {
		announcementDisplayList() {
			return this.$store.state.storage.announcementDisplayList
		},
		announcementDisplay() {
			return (
				this.$store.state.storage.announcementDisplayList.find(
					v => v.id === this.num,
				) || null
			)
		},
		isReactNative() {
			return this.$isReactNative()
		},
		isMac() {
			return /macintosh|mac os x/i.test(navigator.userAgent)
		},
	},
	watch: {
		'$store.state.storage.announcementDisplayShow'() {
			const announcementDisplayShow =
				this.$store.state.storage.announcementDisplayShow
			if (announcementDisplayShow.id) {
				this.getAnnouncementDisplayList(announcementDisplayShow.id)
			} else if (
				announcementDisplayShow.status.toString()?.slice(0, 7) === 'REFRESH'
			) {
				this.getAnnouncementDisplayList()
			} else {
				this.show()
			}
		},
	},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		getAnnouncementDisplayList(id) {
			this.$http.announcement
				.getAnnouncementDisplayList({ ...this.$getStoreId() })
				.then(res => {
					this.$store.commit('storage/setAnnouncementDisplayList', res.data)
					if (
						res.data.length > 0 &&
						this.$store.state.storage.announcementDisplayShow.id
					) {
						this.show(id)
					} else {
						this.hide()
					}
				})
		},

		show(id) {
			if (id) {
				const same = this.announcementDisplayList.find(v => v.id === id)
				if (same) {
					this.num = same.id
				} else {
					this.num =
						this.announcementDisplayList?.length > 0
							? this.announcementDisplayList[0].id
							: null
				}
			} else {
				this.num =
					this.announcementDisplayList?.length > 0
						? this.announcementDisplayList[0].id
						: null
			}
			if (this.num !== null) {
				this.isShow = true
			}
		},
		hide() {
			this.isShow = false
		},
		clean() {
			this.isShow = false
			this.see = []
			setTimeout(() => {
				this.num =
					this.announcementDisplayList.length > 0
						? this.announcementDisplayList[0].id
						: null
			}, 300)
		},
		nextTime() {
			if (this.announcementDisplay) {
				this.see = [...this.see, this.announcementDisplay.id]
			}
			const num = this.announcementDisplayList.find(
				(v, i) => !this.see.find(l => l === v.id),
			)
			if (num) {
				this.num = num.id
			} else {
				this.clean()
			}
		},
		noTime() {
			return new Promise((resolve, reject) => {
				const id = this.announcementDisplay?.id
				this.$http.announcement
					.updateAnnouncementDisplay({
						announcementId: id,
						display: false,
						...this.$getStoreId(),
					})
					.then(res => {
						this.$store.commit(
							'storage/setAnnouncementDisplayList',
							this.announcementDisplayList.filter(v => v.id !== id),
						)
						this.nextTime()
						resolve() // Resolve the Promise to indicate successful completion
					})
					.catch(error => {
						reject(error) // Reject the Promise if there is an error
					})
			})
		},
		clearCache() {
			this.noTime().then(() => {
				if (this.isReactNative && !this.isMac) {
					window.ReactNativeWebView?.postMessage(
						JSON.stringify({
							type: 'clearCache',
						}),
					)
				} else {
					window.location.href = window.location.href + '?reload=true'
				}
			})
		},
		appUpdate() {
			if (!this.isReactNative) {
				// 非 react native 代表不是app 跳轉也沒用,視同 再提醒
				this.nextTime()
				return
			}

			if (this.isMac) {
				this.verifyVersionOtherwiseJumpURL(
					'APP_VERSION_IOS',
					this.$store.state.storage.iosVersion,
					this.announcementDisplay.iosAppUrl,
				)
			} else {
				this.verifyVersionOtherwiseJumpURL(
					'APP_VERSION_ANDROID',
					this.$store.state.storage.androidVersion,
					this.announcementDisplay.androidAppUrl,
				)
			}
		},
		// 驗證版本否則跳轉網址
		verifyVersionOtherwiseJumpURL(key, currentVersion, link) {
			this.$http.system
				.get({ paramId: key })
				.then(res => {
					const onlineVersion = res.data
					const match = onlineVersion === currentVersion
					console.log('onlineVersion', onlineVersion)
					console.log('currentVersion', currentVersion)
					console.log('match', match)
					if (match) {
						// 版號相等 視同 知道了
						this.noTime().then(() => {
							this.$store.commit('showMessage', '當前版號已是最新')
						})
					} else {
						// 跳轉網址
						console.log('跳轉網址')
						window.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: 'link',
								data: link,
							}),
						)
					}
				})
				.catch(err => {
					// 出現異常 視同再提醒
					this.nextTime()
				})
		},
	},
}
</script>

<style scoped lang="scss">
.announcement {
	padding: 16px;
	border-radius: 16px;
	background: $darkTextColor;
	display: flex;
	flex-direction: column;
	max-height: calc(var(--vh, 1vh) * 90);

	&-title {
		padding-bottom: 16px;
		font-size: 1.5rem;
		font-weight: bold;
		color: $lightTextColor;
		display: flex;
		justify-content: center;
		align-items: center;

		.announcement-close {
			position: absolute;
			right: 8px;
			top: 8px;
			height: 32px;
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: 16px;
				width: 16px;
				opacity: 0.3;
			}
		}
	}

	&-line {
		height: 2px;
		width: 100%;
		background: $lineColor;
	}

	&-body {
		flex: 1;
		overflow-y: auto;

		&-content {
			margin-bottom: 8px;

			&::v-deep {
				.v-textarea {
					.v-input__slot {
						box-shadow: none !important;
						padding: 0 !important;

						.v-text-field__slot {
							margin-right: 0;
						}

						.v-text-field__slot textarea {
							padding-right: 0 !important;
						}
					}
				}
			}
		}
	}

	&-date {
		padding-top: 8px;
		font-size: 1.125rem;
		color: $lightTextColor;
		opacity: 0.4;
		margin-bottom: 24px;
	}

	&-action {
		display: flex;
		align-items: center;

		&::v-deep {
			.v-btn {
				color: $lightTextColor !important;
				border-color: $lineColor !important;
				height: 56px !important;
				flex: 1;
			}
		}
	}

	@include rwd(766) {
		padding: 16px;
		&-title {
			padding-bottom: 13px;
		}

		&-date {
			margin-bottom: 12px;
		}
	}
}
</style>
