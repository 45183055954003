<template>
	<v-menu
		v-model="isShow"
		:close-on-content-click="false"
		left
		offset-overflow
		:nudge-bottom="nudgeBottom"
		transition="scale-transition"
		:nudge-top="nudgeTop"
		min-width="auto"
	>
		<template v-slot:activator="{ on }">
			<v-text-field
				class="calendar__input"
				prepend-icon="mdi-calendar-range"
				:value="getValue"
				v-on="on"
				:disabled="disabled"
				:rules="rules"
				readonly
				outlined
				dense
				single-line
			></v-text-field>
		</template>

		<v-date-picker
			:value="value"
			locale="zh-tw"
			color="com1Color"
			@input="onInput"
			no-title
			scrollable
			:range="range"
			class="calendar"
			:width="width"
		>
			<v-spacer></v-spacer>
		</v-date-picker>
	</v-menu>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyDatePicker',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		nudgeTop: {
			required: false,
			type: Number,
			default: 16,
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		range: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	data() {
		return {
			isShow: false,
		}
	},
	computed: {
		width() {
			return 274 + this.scaleFont * 16 * 7 * 0.8
		},
		getValue() {
			return this.value.toString().replace(/,/g, '~')
		},
		validate() {
			return this.rules.length > 0
		},
		nudgeRight() {
			const { labelPosition, labelWidth } = this
			if (labelPosition === 'left') {
				return typeof labelWidth === 'number' ? labelWidth + 10 : 38
			}
			return 0
		},
		nudgeBottom() {
			const { labelPosition, labelWidth } = this
			if (labelPosition === 'top') {
				return 62
			}
			return 40
		},
		scaleFont() {
			return this.$store.state.storage.scaleFont || 1
		},
	},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onInput(val) {
			if (this.range) {
				if (val.length === 2) {
					// 檢查開始時間是否晚於結束時間
					if (new Date(val[0]) > new Date(val[1])) {
						// 如果開始時間晚於結束時間，則清除選擇的日期範圍
						this.$store.commit('showMessage', {
							text: '開始時間晚於結束時間!',
							color: 'error',
						})
						val = []
					} else {
						this.isShow = false
					}
				}
			} else {
				this.isShow = false
			}
			//
			this.$emit('updateValue', val)
			this.$emit('onInput', val)
		},
	},
}
</script>
<style lang="scss" scoped>
.calendar__input {
	min-width: calc(100px + 6rem);
	&::v-deep {
		&.v-input {
			.v-input__icon {
				.mdi-calendar-range::before {
					content: '';
					background-image: url('../assets/common_btn_calendar.png');
					height: 22px;
					width: 22px;
				}
			}
		}
	}
}
.calendar {
	width: 100%;
	&::v-deep {
		.v-btn {
			border-radius: 50%;
			height: calc(20px + 1.2rem);
			width: calc(20px + 1.2rem);
			.v-btn__content {
				font-size: 0.8rem;
			}
		}
		.v-date-picker-table {
			min-height: calc(200px + 4rem);
			height: auto;
			th {
				font-size: 0.8rem;
			}
		}
	}
}
</style>
