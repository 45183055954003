<template>
	<div class="tab">
		<div class="tab-header">
			<template v-for="(e, i) in items">
				<div class="tab-wrap" :key="i">
					<router-link
						class="tab-link"
						:class="{
							'tab-link--active':
								typeof value === 'string' &&
								(e.value === value ||
									(routeBeginsWith && e.value.startsWith(routeBeginsWith))),
						}"
						:to="e.path"
						@click.native="onRouteClick(e.value)"
					>
						<span :class="{ last: i === items.length - 1 }">{{ e.label }}</span>
					</router-link>
				</div>
				<div
					class="logout"
					v-if="!manager && showLogoutBtn && i === items.length - 1"
				>
					<v-btn
						class="logout-btn"
						color="selectColor"
						large
						dark
						@click="logoutModal = true"
					>
						登出
					</v-btn>
					<MyModal
						v-model="logoutModal"
						@onCancel="logoutModal = false"
						@onSubmit="onLogout"
						title=""
						submitName="是"
						cancelName="否"
						:btnSize="false"
						persistent
					>
						<MyDeleteText title="登出">確定要登出嗎？</MyDeleteText>
					</MyModal>
				</div>
			</template>
			<div class="active-line"></div>
		</div>
		<div class="tab-second" v-if="secondItems.length > 0">
			<div class="tab-second-left">
				<template v-for="(e, i) in secondItems">
					<router-link
						class="tab-btn"
						:class="{
							'tab-btn--active': e.path === value,
						}"
						v-if="e.path"
						:to="e.path"
					>
						<span :class="{ last: i === secondItems.length - 1 }">{{
							e.label
						}}</span>
					</router-link>
					<div
						v-else
						class="tab-btn"
						@click.stop="onRouteClick(e.value)"
						:class="{
							'tab-btn--active': e.value === secondValue,
						}"
					>
						<span :class="{ last: i === secondItems.length - 1 }">{{
							e.label
						}}</span>
					</div>
				</template>
        <slot />
			</div>
			<div class="tab-second-right">

			</div>
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
import MyDeleteText from '@/components/DeleteText.vue'
import MyModal from '@/components/Modal.vue'

export default {
	name: 'MyTabHeader',
	components: { MyModal, MyDeleteText },
	// components: { COMPONENT_NAME },
	props: {
		items: {
			required: true,
			type: Array,
		},
		secondItems: {
			required: false,
			type: Array,
			default: () => [],
		},
		value: {
			required: false,
			type: String | Number,
		},
		secondValue: {
			required: false,
			type: String | Number,
		},
		routeBeginsWith: {
			required: false,
			type: String,
		},
		showLogoutBtn: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'update',
	},
	data() {
		return { logoutModal: false }
	},
	computed: {
		manager() {
			return this.$store.state.storage.manager
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onRouteClick(value) {
			this.$emit('update', value)
			this.$emit('onClick', value)
		},
		onLogout() {
			this.$store.dispatch('logout')
		},
	},
}
</script>
<style lang="scss" scoped>
.tab-header {
	background: #fff;
	border-bottom: 1px solid #e7e8ef;
	display: flex;
	align-items: flex-end;
	border-bottom: solid 2px $lineColor;

	@include rwd(765) {
		padding: 0;
		overflow-x: auto;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.tab-wrap {
	@include rwd(765) {
		margin-right: 0 !important;
	}
}

.tab-link {
	display: block;
	color: $lightTextColor;
	font-size: calc(14px + 0.25rem);

	text-decoration: none;
	border-bottom: 3px solid transparent;
	//padding: 16px 12px;
	padding-bottom: 11px;
	transition: 0.4s ease;
	cursor: pointer;
	position: relative;
	opacity: 0.7;
	&--active,
	&:hover {
		opacity: 1;
		font-weight: 900;
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 4px;
			bottom: -6px;
			border-radius: 8px;
			background: $lightTextColor;
		}
	}
	> span {
		padding: 0 24px;
		border-right: solid $lineColor 2px;
		&.last {
			border-right: solid rgba(255, 255, 255, 0) 2px;
		}
	}

	@include rwd(765) {
		white-space: nowrap;
	}
	.nums {
		background: #ff5252;
		border-radius: 100ex;
		color: #fff;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		font-size: 0.75rem;
		font-weight: 900;
		font-style: normal;
		margin-right: 8px;
		transform: translateY(-1px);
	}
}

.tab-second {
	width: 100%;
	padding: 16px 0 8px 0;
	display: flex;
	align-items: center;
	margin-right: 8px;
	flex-wrap: wrap;

	&-left {
		overflow-x: auto;
		overflow-y: hidden;
		display: flex;
		align-items: center;
		margin-right: 16px;
		margin-bottom: 8px;
		.tab-btn {
			min-width: 96px;
			position: relative;
			height: 48px;
			padding: 0 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba($com2Color, 0.1);
			font-size: calc(8px + 0.5rem);
			color: $lightTextColor;
			border-radius: 8px;
			margin-right: 8px;
			cursor: pointer;
			&--active {
				background: $com2Color;
				color: $darkTextColor;
			}
		}
	}
	&-right {
		flex: 1;
		min-height: 48px;
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: 8px;
	}
	@include rwd(800) {
		flex-direction: column;
		&-left,
		&-right {
			width: 100%;
			margin-right: 0;
		}
		&-right {
			margin-top: 10px;
		}
	}
}

.logout {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 3px;
	&-btn {
	}
}
</style>
