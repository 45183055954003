import service from './service'

export default {
	//新增公告公版
	add: params => service.post(`back/announcementTemplate/add`, params),

	//編輯公告公版
	update: params => service.put(`back/announcementTemplate/update`, params),

	//刪除公告公版
	remove: params => service.delete(`back/announcementTemplate/remove`, { params }),

	//公告公版分頁
	getPage: params => service.post(`back/announcementTemplate/getPage`, params),

	//公告公版排序
	sort: params => service.put(`/back/announcementTemplate/updateSort`, params),

	// 取得公告公版
	getDetail: params => service.get(`back/announcementTemplate/get`, { params }),
}
