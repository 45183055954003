<template>
	<div v-if="value.length">篩選條件：{{ searchFilter }}</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MySearchFilter',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: Array,
			default: () => [],
		},
	},
	// data() {return {}},
	computed: {
		searchFilter() {
			return this.value.reduce(
				(last, [key, value], index) =>
					(last += `${index > 0 ? '、' : ''}[${key}] ${value}`),
				'',
			)
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
div {
	font-size: 0.875rem;
	color: rgba(133, 133, 133, 1);
	margin: 8px 0;
}
</style>
