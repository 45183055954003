import Vue from 'vue'
import Vuex from 'vuex'
import global from './global'
import home from './home'
import storage from './storage'
import socket from './socket'
import order from './order'
import store from './store'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'adminVuex',
			paths: ['storage'],
		}),
	],
	modules: { home, storage, socket, order, store },
	...global,
})
