import service from './service'

export default {
	//合約方案列表
	getContractSchemeList: () =>
		service.get(`back/announcement/getContractSchemeList`),
	//合約狀態列表
	getContractStatusList: params =>
		service.get(`back/announcement/getContractStatusList`, { params }),
	getTemplateList: params =>
		service.get(`back/announcement/getTemplateList`),
	//店家下拉選單
	getStoreList: params => service.post(`back/announcement/storeList`, params),
	//新增公告
	add: params => service.post(`back/announcement/add`, params),
	//編輯公告
	update: params => service.put(`back/announcement/update`, params),
	//刪除公告
	remove: params => service.delete(`back/announcement/remove`, { params }),
	//公告分頁
	getPage: params => service.post(`back/announcement/getPage`, params),

	//公告排序
	sort: params => service.put(`/back/announcement/updateSort`, params),
	// 取得公告
	getDetail: params => service.get(`back/announcement/get`, { params }),

	// 取得公告
	getAnnouncementDisplayList: params =>
		service.get(`back/announcement/getDisplayList`, { params }),
	// 不再顯示公告
	updateAnnouncementDisplay: params =>
		service.put(`back/announcement/updateDisplay`, params),
}
