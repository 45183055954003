var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.validManagerCodeDisplay)?_vm._t("default"):_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"keyboard"},[_c('div',{staticClass:"input-title"},[_vm._v("管理者識別碼")]),_c('div',{staticClass:"dots"},_vm._l((6),function(item,index){return _c('div',{key:index,class:[
						_vm.shake ? 'shake' : '',
						index < _vm.managerCode.length ? 'filled-dot' : 'dot',
					]})}),0),_c('div',{staticClass:"content-message"},[_vm._v(_vm._s(_vm.text))]),_c('div',{staticClass:"btns"},_vm._l((_vm.btns),function(e){return _c('div',{key:e.value,staticClass:"btn-wrap",on:{"click":function($event){return _vm.onClickBtn(e)}}},[_c('div',{class:{
							'btn-active': e.selected && e.value !== _vm.BACK,
							btn: true,
							empty: e.value === _vm.EMPTY,
							back: e.value === _vm.BACK,
						}},[(e.value === _vm.BACK)?_c('img',{attrs:{"src":require("../assets/quantity_btn_space.png"),"alt":"backspace"}}):(e.value !== _vm.EMPTY)?_c('span',[_vm._v(_vm._s(e.value))]):_vm._e()])])}),0)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }