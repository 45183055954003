<template>
	<div class="my-quantity-counter" :class="{ 'scale-font-3': scaleFont === 3 }">
		<!--最少可選 開始 -->
		<div class="quantity-section">
			<span class="count-title">最少可選</span>
			<div class="right-side">
				<span class="minus-trash-block">
					<v-btn
						class="count-btn"
						color="primary"
						:disabled="getLeastQuantity === 0"
						@click="toggleLeastCounterDown"
						fab
						depressed
					>
						<v-icon class="minus">mdi-minus</v-icon>
					</v-btn>
				</span>

				<span class="count">{{ getLeastQuantity }}</span>

				<v-btn
					class="count-btn"
					fab
					depressed
					color="primary"
					@click="toggleLeastCounterUp"
				>
					<v-icon class="plus">mdi-plus</v-icon>
				</v-btn>
			</div>
		</div>
		<!--最少可選 結束 -->

		<!--最多可選 開始-->
		<div class="quantity-section">
			<span
				class="count-title"
				:style="{ marginLeft: scaleFont !== 3 ? '10px' : '0px' }"
				>最多可選</span
			>
			<div class="right-side">
				<span class="minus-trash-block">
					<v-btn
						class="count-btn"
						color="primary"
						:disabled="getMostQuantity === null"
						@click="toggleMostCounterDown"
						fab
						depressed
					>
						<v-icon class="minus">mdi-minus</v-icon>
					</v-btn>
				</span>

				<span class="count">{{ displayMostQuantity }}</span>

				<v-btn
					class="count-btn"
					fab
					depressed
					color="primary"
					@click="toggleMostCounterUp"
				>
					<v-icon class="plus">mdi-plus</v-icon>
				</v-btn>
			</div>
		</div>
		<!--最多可選 結束-->

		<div
			v-if="isQuantityError"
			style="
				margin-top: 20px;
				display: flex;
				align-items: center;
				font-size: 1.25rem;
				color: red;
				width: 100%;
				font-weight: bold;
				justify-content: center;
			"
		>
			最多可選項不可小於最少可選項!
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'MyQuantityCounter',
	props: {
		leastQuantity: {
			type: Number,
			default: 1,
		},
		mostQuantity: {
			type: Number,
			default: null,
		},
		quantityError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		scaleFont() {
			return this.$store.state.storage.scaleFont || 1
		},
		getLeastQuantity: {
			get() {
				return this.leastQuantity
			},
			set(value) {
				const newValue = this.parseQuantity(false, value)
				this.$emit('update:leastQuantity', newValue)
			},
		},
		getMostQuantity: {
			get() {
				return this.mostQuantity
			},
			set(value) {
				const newValue = this.parseQuantity(true, value)
				this.$emit('update:mostQuantity', newValue)
			},
		},
		getQuantityError: {
			get() {
				return this.quantityError
			},
			set(value) {
				this.$emit('update:quantityError', value)
			},
		},
		displayMostQuantity() {
			const mostQuantity = this.getMostQuantity
			if (mostQuantity === null) {
				return '不限'
			}
			return mostQuantity
		},
		isQuantityError() {
			const mostQuantity = this.getMostQuantity
			const leastQuantity = this.getLeastQuantity
			const error =
				mostQuantity !== null &&
				mostQuantity !== '' &&
				parseInt(mostQuantity, 10) < parseInt(leastQuantity, 10)
			this.getQuantityError = error
			return error
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		toggleLeastCounterDown() {
			if (this.getLeastQuantity > 0) {
				this.getLeastQuantity = this.getLeastQuantity - 1
				return
			}
			this.getLeastQuantity = 0
		},
		toggleLeastCounterUp() {
			this.getLeastQuantity = this.getLeastQuantity + 1
		},
		toggleMostCounterDown() {
			if (this.getMostQuantity === null || this.getMostQuantity === 1) {
				this.getMostQuantity = null
				return
			}
			this.getMostQuantity = this.getMostQuantity - 1
		},
		toggleMostCounterUp() {
			if (this.getMostQuantity === null) {
				this.getMostQuantity = 1
				return
			}
			this.getMostQuantity = this.getMostQuantity + 1
		},
		parseQuantity(isMost, value) {
			let numValue = parseInt(value, 10)
			if (isMost) {
				//最多數量
				if (isNaN(numValue) || numValue <= 0) {
					return null
				} else {
					return numValue
				}
			} else {
				// 最少數量
				if (isNaN(numValue) || numValue < 0) {
					return 0
				} else {
					return numValue
				}
			}
		},
	},
})
</script>

<style lang="scss" scoped>
.my-quantity-counter {
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
}
.quantity-section {
	display: flex;
	align-items: center;
	flex: 1 1 50%;
	margin-top: 15px;
}
.right-side {
	display: flex;
	align-items: center;
}

.scale-font-3 .quantity-section {
	flex-basis: 100%;
}

.scale-font-3 .quantity-section .count-title {
	flex-basis: 30%;
}

.scale-font-3 .quantity-section .right-side {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	margin-left: 30px;
	margin-right: 30px;
}

.count-btn {
	height: calc(16px + 0.75rem) !important;
	width: calc(16px + 0.75rem) !important;
	min-width: calc(16px + 0.75rem) !important;
	padding: 0 !important;
}

.count-title {
	color: #000;
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 24px;
	min-width: 5rem;
}

.count {
	color: #000;
	display: inline-block;
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 24px;
	padding: 0 12px;
	min-width: 3.5rem;
	text-align: center;
	@media only screen and (max-width: 568px) {
		font-size: 1rem;
		padding: 0 2px;
	}
	@media only screen and (max-width: 350px) {
		min-width: initial;
	}
}

.plus,
.minus {
	cursor: pointer;
	color: #888;
	width: 20px;
	height: 20px;
	text-align: center;
}

.disabled.plus,
.disabled.minus {
	cursor: not-allowed;
}

.theme--light.v-icon:focus::after {
	opacity: 0;
}

.minus-trash-block {
	position: relative;
	display: flex;
	justify-content: flex-start;
}

.minus-trash-block.isTrash {
	.minus {
		opacity: 0;
	}
}

@media (max-width: 600px) {
	.my-quantity-counter .count-title,
	.my-quantity-counter .minus-trash-block,
	.my-quantity-counter .count,
	.my-quantity-counter .count-btn {
		flex-basis: 100%;
	}
}
</style>
