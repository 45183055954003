<template>
	<MyFormRow
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:labelPosition="labelPosition"
		:color="color"
	>
		<div class="custom-radio-group-wrap" :style="wrapStyle">
			<v-radio-group
				class="custom-radio-group"
				v-model="_value"
				v-for="(item, index) in items"
				:key="item[defaultValue]"
				:rules="rules"
			>
				<div
					class="custom-radio-wrap"
					:style="{ 'flex-direction': isAppendWrap ? 'column' : 'row' }"
				>
					<v-radio
						class="custom-radio"
						:key="item[defaultValue] + index"
						:label="item[defaultLabel]"
						:value="item[defaultValue]"
						:disabled="disabled || item[defaultDisabled]"
						@click="e => onClick(e, item[defaultValue])"
					>
						<template #label>
							<slot :name="'label' + index" :item="{ ...item, index }">
								<slot name="label" :item="{ ...item, index }">{{
									item[defaultLabel]
								}}</slot>
							</slot>
						</template>
					</v-radio>
					<div class="custom-radio-append">
						<slot :name="'append' + index" :item="{ ...item, index }">
							<slot name="append" :item="{ ...item, index }"></slot>
						</slot>
					</div>
				</div>
				<div class="content">
					<slot name="content" :item="{ ...item, index }"></slot>
				</div>
			</v-radio-group>
		</div>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyRadio',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String | Number | Boolean,
			default: null,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		// 有值就多個，value 回傳數組
		// label, value?, disabled?
		items: {
			required: false,
			type: Array,
			default: () => [],
		},
		// 多個的 radio 們是否要換行
		isWrap: {
			required: false,
			type: Boolean,
			default: true,
		},
		// 是否禁用，設定 true 單個及多個全部進用，多個可用 item.disabled 禁用
		disabled: {
			required: false,
			type: Boolean,
		},
		required: {
			required: false,
			type: Boolean,
			default: true,
		},
		isAppendWrap: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		defaultValue: {
			required: false,
			type: String,
			default: 'value',
		},
		defaultLabel: {
			required: false,
			type: String,
			default: 'label',
		},
		defaultDisabled: {
			required: false,
			type: String,
			default: 'disabled',
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
		_value: {
			get() {
				return this.value
			},
			set(checked) {
				this.$emit('updateValue', checked)
				this.$emit('onChange', checked)
			},
		},
		wrapStyle() {
			return { 'flex-direction': this.isWrap ? 'column' : 'row' }
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onClick(v, value) {
			if (!this.required) {
				if (this._value === value) {
					this._value = null
				}
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.custom-radio-group-wrap {
	width: 100%;
	::v-deep {
		.v-radio {
			margin-bottom: 0 !important;
			max-width: 80%;
			&.v-radio--is-disabled {
				opacity: 0.3;
			}
		}
		.v-input {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12px !important;
			.v-input__slot {
				margin-bottom: 0px;
				.v-label {
					margin-left: 12px;
					font-size: 1rem;
					color: $lightTextColor;
				}
			}
			.custom-radio-append {
			}
			//&:last-child {
			//	margin-bottom: 0 !important;
			//}
		}
	}

	.custom-radio-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.content {
}
</style>
