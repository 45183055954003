import XLSX from 'xlsx'
import XLSXStyle from './xlsx-style'
import { saveAs } from 'file-saver'

function datenum(v, date1904) {
	if (date1904) v += 1462
	var epoch = Date.parse(v)
	return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)
}

function sheet_from_array_of_arrays(data, opts) {
	var ws = {}
	var range = {
		s: {
			c: 10000000,
			r: 10000000,
		},
		e: {
			c: 0,
			r: 0,
		},
	}
	for (var R = 0; R != data.length; ++R) {
		for (var C = 0; C != data[R].length; ++C) {
			if (range.s.r > R) range.s.r = R
			if (range.s.c > C) range.s.c = C
			if (range.e.r < R) range.e.r = R
			if (range.e.c < C) range.e.c = C
			var drc = data[R][C]
			var cell =
				R === 0
					? {
							v: drc.value,
							s: {
								font: {
									name: '宋体',
									bold: true,
									sz: 14,
								},
								fill: {
									fgColor: {
										rgb: 'C0C0C0',
									},
								},
								alignment: {
									vertical: 'center',
								},
							},
					  }
					: {
							v: drc.value,
							s: drc.style
								? drc.style(drc, R - 1)
									? drc.style(drc, R - 1).validate && drc.style(drc, R - 1)
									: { font: { sz: 10 } }
								: { font: { sz: 10 } },
					  }
			if (cell.v == null) continue
			var cell_ref = XLSX.utils.encode_cell({
				c: C,
				r: R,
			})

			if (typeof cell.v === 'number') cell.t = 'n'
			else if (typeof cell.v === 'boolean') cell.t = 'b'
			else if (cell.v instanceof Date) {
				cell.t = 'n'
				cell.z = XLSX.SSF._table[14]
				cell.v = datenum(cell.v)
			} else cell.t = 's'
			ws[cell_ref] = cell
		}
	}
	if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range)

	ws['!rows'] = [{ hpt: 20 }]

	// 自適應表格欄位寬度
	if (data.length > 1) {
		ws['!cols'] = []
		const header = data[0]
		const noHeaderData = data.slice(1)
		header.forEach((e, i) => {
			let wch = typeof e.value === 'string' ? e.value.length * 3 : 3
			noHeaderData.forEach(f => {
				const column = f[i]
				const valLen = column.value?.length * 1.8 || 0
				if (typeof column.value === 'string' && valLen > wch) wch = valLen
			})
			ws['!cols'][i] = { wch }
		})
	}
	// $devLog(ws)
	return ws
}

function Workbook() {
	if (!(this instanceof Workbook)) return new Workbook()
	this.SheetNames = []
	this.Sheets = {}
}

function s2ab(s) {
	var buf = new ArrayBuffer(s.length)
	var view = new Uint8Array(buf)
	for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
	return buf
}

function formatJson(filterVal, jsonData) {
	return jsonData.map(v =>
		filterVal.map(j => ({ value: v[j.key], style: j.style })),
	)
}

function exportJson2Excel(filterVal, jsonData, defaultTitle) {
	/* original data */

	var data = formatJson(filterVal, jsonData)
	data.unshift(filterVal.map(e => ({ value: e.name })))
	var ws_name = 'SheetJS'

	var wb = new Workbook(),
		ws = sheet_from_array_of_arrays(data)

	/* add worksheet to workbook */
	wb.SheetNames.push(ws_name)
	wb.Sheets[ws_name] = ws

	var wbout = XLSXStyle.write(wb, {
		bookType: 'xlsx',
		bookSST: false,
		type: 'binary',
	})
	var title = defaultTitle || '列表'
	saveAs(
		new Blob([s2ab(wbout)], {
			type: 'application/octet-stream',
		}),
		title + '.xlsx',
	)
}

export default Vue => (Vue.prototype.$exportJson2Excel = exportJson2Excel)
