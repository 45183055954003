const checkLazy = child => {
	const TABLE = child
		? child
		: document.getElementsByClassName('v-data-table__wrapper')[0]
	const TABLE_HEIGHT = TABLE.offsetHeight
	const { top: TABLE_DISTANCE_WITH_TOP } = TABLE.getBoundingClientRect()
	const DISTANCE_HEIGHT =
		TABLE_DISTANCE_WITH_TOP > 0 ? TABLE_DISTANCE_WITH_TOP : 0
	const WINDOW_SCROLL_Y = window.scrollY
	const TABLE_DISTANCE = TABLE_HEIGHT + DISTANCE_HEIGHT - window.innerHeight

	if (WINDOW_SCROLL_Y > TABLE_DISTANCE) {
		return true
	}
}

export default Vue => {
	Vue.prototype.$checkLazy = checkLazy
}
