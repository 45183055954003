export function countOrderComboListPrice(order) {
	let total = 0
	order.comboList?.forEach(e => {
		const comboCount = e.count
		let comboPrice = e.comboItemPrice + (e.comboPrice ?? 0)
		e.itemOptionDetailList?.forEach(f => {
			comboPrice += f.optionDetailPrice * f.count
		})
		total += comboCount * comboPrice
	})
	return total * order.count
}

export function countProductPrice(
	product,
	{ optionKey, productPriceKey, optionPriceKey } = {},
) {
	const { count } = product
	const _optionKey = optionKey ?? 'option'
	const _productPriceKey = productPriceKey ?? 'price'
	const _optionPriceKey = optionPriceKey ?? 'price'
	return (
		product[_optionKey]?.reduce(
			(p, e) => p + e.count * e[_optionPriceKey] * count,
			count * product[_productPriceKey],
		) || 0
	)
}

export function getOrderTotal(list, keyMap = {}) {
	return (
		list?.reduce(
			(p, e) => p + countProductPrice(e, keyMap) + countOrderComboListPrice(e),
			0,
		) || 0
	)
}

function countUpgradeListTotal(upgrade) {
	return upgrade.upgradeList?.reduce(
		(p, f) => p + f.price + countOptionTotal(f),
		0,
	)
}

function countOptionTotal(option) {
	return option.optionList?.reduce((p, f) => p + f.count * f.price, 0) ?? 0
}

export function getOrderTotal2(initialPrice, order) {
	return (
		initialPrice +
		(countOptionTotal(order) + countUpgradeListTotal(order) + order.price) *
			order.count
	)
}

export function getOrderListTotal(data) {
	return data.reduce(getOrderTotal2, 0)
}

// 檢測訂單餐點是否相同
export function checkSameOrder(originOrderList, newOrder) {
	// {[i: number]: number(newOrder.count)}
	const waitPushOrderDict = {}
	const sortFun = key => (a, b) => a[key] - b[key]
	for (let i = 0; i < originOrderList.length; i++) {
		const e = originOrderList[i]
		const newComboList = newOrder.comboList?.sort(sortFun('comboId')) ?? []
		const eComboList = e.comboList?.sort(sortFun('comboId')) ?? []
		const newOption = newOrder.option?.sort(sortFun('id')) ?? []
		const eOption = e.option?.sort(sortFun('id')) ?? []
		if (
			/*e.id === newOrder.id
      &&*/ e.itemId === newOrder.itemId &&
			e.remark === newOrder.remark &&
			e.name === newOrder.name &&
			e.price === newOrder.price &&
			eComboList.every((f, j) => {
				const f2 = newComboList[j]
				const fItemOptionDetailList =
					f.itemOptionDetailList?.sort(sortFun('option')) ?? []
				const newItemOptionDetailList =
					f2.itemOptionDetailList?.sort(sortFun('option')) ?? []
				return (
					f.comboId === f2.comboId &&
					f.comboItemName === f2.comboItemName &&
					f.comboItemId === f2.comboItemId &&
					f.comboItemPrice === f2.comboItemPrice &&
					f.comboName === f2.comboName &&
					f.comboPrice === f2.comboPrice &&
					f.count === f2.count &&
					f.countStatus === f2.countStatus &&
					(fItemOptionDetailList.length === newItemOptionDetailList.length
						? fItemOptionDetailList.length === 0
							? true
							: fItemOptionDetailList.every((g, k) => {
									const g2 = newItemOptionDetailList[k]
									return (
										g.count === g2.count &&
										g.countStatus === g2.countStatus &&
										g.option === g2.option &&
										g.optionDetailId === g2.optionDetailId &&
										g.optionDetailName === g2.optionDetailName &&
										g.optionDetailPrice === g2.optionDetailPrice &&
										g.parentName === g2.parentName
									)
							  })
						: false)
				)
			}) &&
			(eOption.length === newOption.length
				? eOption.length === 0
					? true
					: eOption.every((f, j) => {
							const f2 = newOption[j]
							return (
								f.count === f2.count &&
								f.countStatus === f2.countStatus &&
								// &&f.detailId===f2.detailId
								// &&f.id===f2.id
								f.name === f2.name &&
								f.optionId === f2.optionId &&
								f.price === f2.price
							)
					  })
				: false)
		) {
			waitPushOrderDict[i] = newOrder.count
			break
		}
	}
	return waitPushOrderDict
}
