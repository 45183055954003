<template></template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MySearchTextInput',
	// components: { COMPONENT_NAME },
	// props: {},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped></style>
