<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="calc(290px + 5rem)"
		content-class="modal-dialog-no-select"
	>
		<div class="store-pos_status_calc">
			<div class="store-pos_status_title">
				<div>
					{{ isNum ? itemName : '內用桌號' }}
				</div>
			</div>
			<div class="top">
				<div class="top_counter">
					<div class="top_counter_input line-box">
						{{ num }}
					</div>

					<v-btn
						class="top_counter_space line-box"
						color="white"
						@click.stop="onSpace"
					>
						<img src="../assets/quantity_btn_space.png" alt="" />
					</v-btn>
				</div>
			</div>
			<div class="keyboard">
				<div class="keyboard_box">
					<div
						class="keyboard_item line-box"
						v-for="e in getCalcList"
						:class="{ dot: e === 97 }"
						:key="e"
						@click.stop="onKeydownCalc(e)"
					>
						{{ getCalcText(e) }}
					</div>
				</div>
				<div class="keyboard_right" v-if="isNum && !isDecimal">
					<v-btn
						class="keyboard_right_btn plus line-box"
						color="white"
						@click.stop="onCount(1)"
					>
						<img src="../assets/common_btn_add.png" alt="" />
					</v-btn>
					<v-btn
						class="keyboard_right_btn minus line-box"
						color="white"
						@click.stop="onCount(-1)"
					>
						<img src="../assets/common_btn_reduce.png" alt="" />
					</v-btn>
				</div>
				<div class="keyboard_right" v-if="isNum && isDecimal">
					<v-btn
						class="keyboard_right_btn plus line-box"
						color="white"
						@click.stop="onClear"
					>
						清
						<br />
						除
					</v-btn>
				</div>
			</div>
			<div class="calc_btnList">
				<v-btn class="calc_btn cancel line-box" @click.stop="onCancel"
					>取消</v-btn
				>
				<v-btn class="calc_btn submit" color="com1Color" @click.stop="onSubmit"
					>確定</v-btn
				>
			</div>
		</div>
	</v-dialog>
</template>

<script>
const CALC_DOT = 97
const CALC_DOUBLE_ZERO = 98
const CALC_ZERO = 99
const CALC_SUBMIT_NUM = 100

const enumCalcText = {
	[CALC_DOT]: '‧',
	[CALC_ZERO]: '清除',
	[CALC_SUBMIT_NUM]: '確認',
	[CALC_DOUBLE_ZERO]: '00',
}

export default {
	name: 'MyInsideModal',
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		orderInside: {
			type: String,
			default: '',
		},
		isNum: {
			type: Boolean,
			default: false,
		},
		inputCount: {
			type: Number,
			default: 0,
		},
		itemName: {
			type: String,
			default: '',
		},
	},
	computed: {
		isDecimal() {
			return this.$store.state.storage.quantityKeyboardType === 'DECIMAL'
		},
		getCalcList() {
			if (this.isNum) {
				if (this.isDecimal) {
					return this.calcDecimalNum
				}
				return this.calcNum
			}
			return this.calc
		},
	},
	data() {
		return {
			num: '',
			calc: ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '00', CALC_ZERO],
			calcNum: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, CALC_DOUBLE_ZERO, CALC_ZERO],
			calcDecimalNum: [
				7,
				8,
				9,
				4,
				5,
				6,
				1,
				2,
				3,
				CALC_DOUBLE_ZERO,
				0,
				CALC_DOT,
			],
			clickKeyBoard: false,
		}
	},
	watch: {
		dialog() {
			if (this.dialog) {
				this.clickKeyBoard = false
				if (this.isNum) {
					if (this.isDecimal) {
						this.num = this.inputCount.toString()
					} else {
						this.num = this.inputCount
					}
				} else {
					this.num = this.orderInside
				}
			}
		},
	},
	methods: {
		onSpace() {
			if (this.isNum && !this.isDecimal) {
				this.changeCount(0, false, false, true)
			} else {
				if (this.num.length > 1) {
					this.num = this.num.slice(0, this.num.length - 1)
				} else {
					if (this.isNum) {
						this.num = '0'
						this.clickKeyBoard = false
					} else {
						this.num = ''
					}
				}
			}
		},
		changeCount(num, add, concat, space) {
			let count = this.num
			if (space && count < 10) {
				return
			}
			if (add && count + num < 0) {
				this.num = 0
				return
			}
			this.num = concat
				? Number(`${count}${num}`)
				: space
				? Math.floor(count / 10)
				: add
				? Number(num) + count
				: num
		},
		onKeydownCalc(c) {
			if (this.isNum) {
				if (this.isDecimal) {
					this.handleDecimalCalc(c)
					return
				}
				switch (c) {
					case CALC_DOUBLE_ZERO:
						this.changeCount('00', true, true)
						break
					case CALC_ZERO:
						this.num = 0
						break

					default:
						if (this.clickKeyBoard) {
							this.changeCount(c, false, true)
						} else {
							this.changeCount(c)
							this.clickKeyBoard = true
						}

						break
				}
			} else {
				if (c === 99) {
					this.num = ''
				} else {
					if (this.num.length < 10 && c !== '00') {
						this.num = `${this.num}${c}`
					} else if (this.num.length === 9 && c === '00') {
						this.num = `${this.num}0`
					} else if (this.num.length < 9 && c === '00') {
						this.num = `${this.num}00`
					}
				}
			}
		},
		handleDecimalCalc(c) {
			const currentValue = this.num
			if (currentValue[0] === '0' && (c === 98 || c === 0)) {
				// 開頭不能兩個00
				return
			}

			if (currentValue.includes('.')) {
				if (c === 97) {
					// 只能有一個.
					return
				}
				const parts = currentValue.split('.')
				if (parts[1].length === 2) {
					// 只到小數兩位
					return
				}
			}

			if (c === 97) {
				this.num = `${this.num}.`
				return
			}

			if (currentValue[0] === '0' && !currentValue.includes('.')) {
				// 覆蓋0開頭
				this.num = `${c}`
				return
			}

			if (this.num.length < 8 && c !== 98) {
				this.num = `${this.num}${c}`
			} else if (this.num.length === 7 && c === 98) {
				this.num = `${this.num}0`
			} else if (this.num.length < 7 && c === 98) {
				this.num = `${this.num}00`
			}
		},
		getCalcText(num) {
			return enumCalcText[num] ?? num
		},
		onClear() {
			this.num = '0'
			this.clickKeyBoard = false
		},
		onCount(num) {
			this.changeCount(num, true)
			this.clickKeyBoard = false
		},
		onSubmit() {
			if (this.isNum && this.isDecimal) {
				let result = this.$formatDecimal(this.num)
				this.$emit('onSubmit', result)
			} else {
				this.$emit('onSubmit', this.num)
			}
		},
		onCancel() {
			this.$emit('onCancel')
		},
	},
}
</script>

<style scoped lang="scss">
.store-pos_status {
	.item {
		height: calc(100% / 10);
		padding: 5px 6px;
		&-btn {
			font-weight: bold;
			font-size: 1.125rem;
			height: 100%;
			width: 100%;
			padding: 0 4px;
			letter-spacing: 0px;
		}
	}
	&_title {
		height: calc(32px + 1rem);
		margin-bottom: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.25rem;
		background: $disableColor;
		width: 100%;
		font-weight: bold;
		padding: 0 12px;
		div {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	&_calc {
		padding: 16px;
		width: 100%;
		.line-box {
			border: solid 2px $lineColor !important;
			border-radius: 8px;
			color: $lightTextColor !important;
			background: $darkTextColor !important;
		}
		.top {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: 8px;
			&_name {
				width: 100%;
				margin-bottom: 8px;
				padding: 6px 8px;
				font-weight: bold;
				font-size: 1.55rem;
				text-align: center;
				background: #fcfcfc;
				&.is-product {
					background: #1976d2;
					color: #fff;
				}
			}
			&_counter {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				&_input {
					padding-top: 0;
					margin-top: 0;
					background: $darkTextColor;
					color: $lightTextColor;
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: calc(36px + 1rem);
					font-size: 1.75rem;
					font-weight: bold;
				}

				&_space {
					height: calc(36px + 1rem);
					width: 80px;
					margin-left: 6px;
				}
			}
		}
		.keyboard {
			display: flex;
			align-items: stretch;

			&_box {
				display: flex;
				align-content: flex-start;
				flex-wrap: wrap;
				height: 100%;
				overflow: hidden;
				margin-bottom: 6px;
				flex: 1;
			}
			&_item {
				width: calc((100% - 18px) / 3);
				height: calc(36px + 1rem);
				display: flex;
				font-size: 1.4rem;
				font-weight: bold;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				background: $darkTextColor;
				color: $lightTextColor;
				margin-right: 6px;
				margin-bottom: 6px;
				&:active {
					opacity: 0.8;
				}
				&.dot {
					font-size: 3.5rem;
				}
			}
			&_right {
				height: calc((36px + 1rem) * 4 + 24px);
				display: flex;
				flex-direction: column;
				&_btn {
					width: 80px;
					flex: 1;
					background: $darkTextColor;
					display: flex;
					flex-direction: column;
					margin-bottom: 6px;
				}
			}
		}
		.calc_btnList {
			display: flex;
			.calc_btn {
				color: #fff;
				flex: 1;
				height: 55px;
				font-size: 1.25rem;
				font-weight: bold;
				&.submit {
					margin-left: 6px;
				}
			}
		}
	}
}
</style>
