import service from './service'

export default {
	meal: {
		// 新增餐點
		add: formdata => service.post(`back/item/add`, formdata),
		// 修改餐點
		update: formdata => service.put(`back/item/update`, formdata),
		// 複製餐點
		copy: params => service.put(`back/item/copy`, params),
		// 刪除餐點
		delete: params => service.delete(`back/item/remove`, { params }),
		// 修改餐點排序
		sort: params => service.put(`back/item/updateSort`, params),
		// 取得餐點(單筆)
		get: params => service.get(`back/item/get`, { params }),
		// 取得餐點(多筆)
		getPage: params => service.post(`back/item/getPage`, params),
		// 取得餐點(多筆不分頁)
		getListNoPage: params => service.post(`item/getItemListNoPage`, params),
		// 匯出菜單(Excel)
		exportMealsExcel: params =>
			service.get(`back/item/exportItem`, { params, responseType: 'blob' }),
		// 匯入菜單(Excel)
		importMealsExcel: params => service.post(`back/item/importExcel`, params),
		// 沒有名字的狗傢伙－口味自訂比對
		checkCustomModule: params =>
			service.post(`back/item/getOptionStatus`, params),
		// 沒有名字的狗傢伙－口味自訂比對列表
		// checkCustomModules: (params) => service.post(`option/getOptionStatusList`, params),
		//更改餐點狀態
		updateStatus: params => service.put(`back/item/updateStatus`, params),
		//口味詳細列表
		getOptionList: params => service.get(`back/item/getOptionList`, { params }),
		//批量添加口味
		batchUpdateOption: params =>
			service.put(`back/item/batchUpdateOption`, params),
		getCategoryList: params => service.get(`back/option/mealList`, { params }),
		// 餐點相關字典資料
		getDict: params => service.get(`back/item/getDict`, { params }),
	},
	category: {
		// 新增分類
		add: params => service.post(`back/category/add`, params),
		// 修改分類
		update: params => service.put(`back/category/update`, params),
		// 刪除分類
		delete: params => service.delete(`back/category/remove`, { params }),
		// 修改分類排序
		sort: params => service.put(`back/category/updateSort`, params),
		// 取得分類(單筆)
		get: params => service.get(`back/category/get`, { params }),
		// 取得分類(多筆)
		getList: params => service.post(`back/category/getList`, params),
		// 取得分類(多筆不分頁)
		getListNoPage: params =>
			service.get(`back/category/getSimpleList`, { params }),
		// 修改分類是否需列印標籤
		updateNeedPrintTag: params =>
			service.put(`back/category/updateNeedPrintTag`, params),
		// 分類餐點列表
		getCategoryItemList: params =>
			service.post(`back/pos/getCategoryItemList`, params),
	},
	combo: {
		// 新增升級
		add: params => service.post(`back/upgrade/add`, params),
		// 修改升級
		update: params => service.put(`back/upgrade/update`, params),
		// 複製升級
		copy: params => service.put(`back/upgrade/copy`, params),
		// 刪除升級
		delete: params => service.delete(`back/upgrade/remove`, { params }),
		// 修改升級排序
		sort: params => service.put(`back/upgrade/updateSort`, params),
		// 取得升級(單筆)
		get: params => service.get(`combo/get`, { params }),
		// 取得原始升級(單筆)
		getOrigin: params => service.get(`back/upgrade/getInfo`, { params }),
		// 取得升級(多筆)
		getList: params => service.post(`back/upgrade/getList`, params),
		// 更改升級狀態
		updateStatus: params => service.put(`back/upgrade/updateStatus`, params),
		// 升級菜單列表
		getCategoryList: params => service.get(`back/upgrade/mealList`, { params }),
	},
	module: {
		// 新增模組
		add: params => service.post(`back/option/add`, params),
		// 修改修改模組
		update: params => service.put(`back/option/update`, params),
		// 複製口味
		copy: params => service.put(`back/option/copy`, params),
		// 刪除修改模組
		delete: params => service.delete(`back/option/remove`, { params }),
		// 修改模組排序
		sort: params => service.put(`back/option/updateSort`, params),
		// 取得模組(單筆)
		get: params => service.get(`back/option/get`, { params }),
		// 取得模組(多筆)
		getList: params => service.post(`back/option/getList`, params),
		//更改模組狀態
		updateStatus: params => service.put(`back/option/updateStatus`, params),
		// 沒有名字的狗傢伙－取得模組(多筆不分頁)且符合餐點新增&編輯格式
		getOptionsNoPage: params =>
			service.get(`back/item/getOptionList`, { params }),
	},
	remark: {
		// 新增備註
		add: params => service.post(`back/remark/add`, params),
		// 編輯備註
		update: params => service.put(`back/remark/update`, params),
		// 刪除備註
		delete: params => service.delete(`back/remark/remove`, { params }),
		// 修改備註排序
		sort: params => service.put(`back/remark/updateSort`, params),
		// 取得備註資訊
		get: params => service.get(`back/remark/get`, { params }),
		// 備註列表
		getList: params => service.post(`back/remark/getList`, params),
		// 修改備註顯示狀態
		updateStatus: params => service.put(`back/remark/updateStatus`, params),
	},
}
