<template>
	<div class="tab-body">
		<div
			class="tab-body__container"
			:style="{ 'max-width': containerMaxWidth }"
		>
			<slot></slot>
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyTabBody',
	// components: { COMPONENT_NAME },
	props: {
		maxWidth: {
			required: false,
			type: String | Number,
			default: 500,
		},
	},
	// data() {return {}},
	computed: {
		containerMaxWidth() {
			const { maxWidth } = this
			return typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.tab-body {
	padding: 40px;
	position: relative;
	&::before {
		content: '';
		background: #fff;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 30px;
	}
	@include rwd(500) {
		padding: 20px;
		&::before {
			height: 15px;
		}
	}
}
.tab-body__container {
	margin: 0 auto;
}
</style>
