<template>
	<div class="my-counter">
		<span
			v-if="!noControl"
			class="minus-trash-block"
			:class="{ isTrash: isTrash && del }"
		>
			<v-btn
				class="count-btn"
				color="primary"
				:disabled="disabled || (del ? false : value <= minimum)"
				@click="toggleCounter(false, value)"
				fab
				depressed
			>
				<v-icon class="minus">mdi-minus</v-icon>
			</v-btn>
			<v-icon class="trash" color="#fff" @click="delItem" small
				>mdi-trash-can-outline</v-icon
			>
		</span>
		<!-- <input
      type="number"
      v-model="counter"
      class="count"
      disabled
      :style="{width:counter > 99 ? '15%' : '2%'}"
    />-->
		<span class="count" :style="{ opacity: disabled ? 0.4 : 1 }">{{
			value
		}}</span>
		<v-btn
			v-if="!noControl"
			class="count-btn"
			fab
			depressed
			color="primary"
			:disabled="disabled || (max ? value >= maximum : false)"
			@click="toggleCounter(true, value)"
		>
			<v-icon class="plus">mdi-plus</v-icon>
		</v-btn>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyCounter',
	// components: { COMPONENT_NAME },
	model: {
		prop: 'value',
		event: 'toggleCounter',
	},
	props: {
		max: {
			type: Boolean,
			default: false,
		},
		maximum: {
			type: Number,
		},
		minimum: {
			type: Number,
			default: 0,
		},
		value: {
			type: Number,
			default: 1,
		},
		del: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		noControl: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data() {
		return {
			isTrash: false,
		}
	},
	// computed: {},
	// watch: {},
	// created() {},
	mounted() {
		window.addEventListener(`mouseup`, this.mouseup)
	},
	// beforeDestroy() {},
	methods: {
		toggleCounter(plus, curVal) {
			let counter = this.value
			if (plus) {
				if (!this.disabled) {
					!this.max
						? (counter += 1)
						: counter < this.maximum
						? (counter += 1)
						: false
				}

				this.isTrash = false
			} else {
				this.isTrash = this.value === 1
				if (!this.disabled) {
					counter > (this.del ? 1 : this.minimum) ? (counter -= 1) : false
				}
			}

			if (counter === curVal) {
			} else {
				this.$emit('toggleCounter', counter, plus)
				this.$emit('toggle', { count: counter, isPlus: plus })
			}
		},
		delItem() {
			this.$emit('del')
		},
		mouseup(e) {
			const trash = document.querySelector('.trash')
			if (trash && !trash.contains(e.target)) {
				this.isTrash = false
			}
		},
	},
	beforeDestroy() {
		window.removeEventListener(`mouseup`, this.mouseup)
	},
}
</script>

<style lang="scss" scoped>
.count-btn {
	height: calc(16px + 0.75rem) !important;
	width: calc(16px + 0.75rem) !important;
	min-width: calc(16px + 0.75rem) !important;
	padding: 0 !important;
}
.my-counter {
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.count {
	color: #000;
	display: inline-block;
	vertical-align: top;
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 24px;
	padding: 0 12px;
	min-width: 60px;
	text-align: center;
	@media only screen and (max-width: 568px) {
		font-size: 1rem;
		padding: 0 2px;
	}
	@media only screen and (max-width: 350px) {
		min-width: initial;
	}
}

.plus,
.minus {
	cursor: pointer;
	color: #888;
	width: 20px;
	height: 20px;
	text-align: center;
}

.disabled.plus,
.disabled.minus {
	cursor: not-allowed;
}

.theme--light.v-icon:focus::after {
	opacity: 0;
}

.minus-trash-block {
	position: relative;
	display: flex;
	justify-content: flex-start;

	.trash {
		position: absolute;
		top: 0;
		left: -36px;
		cursor: pointer;
		background: red;
		width: 36px;
		height: 36px;
		opacity: 0;
		z-index: -1;
		transition: all 0.4s;
	}
}

.minus-trash-block.isTrash {
	.minus {
		opacity: 0;
	}
	.trash {
		left: 0;
		opacity: 1;
		z-index: 0;
	}
}
</style>
