<template>
	<div>
		<slot v-if="!$store.state.validManagerCodeDisplay"></slot>
		<div class="wrap" v-else>
			<div class="keyboard">
				<div class="input-title">管理者識別碼</div>
				<div class="dots">
					<div
						v-for="(item, index) in 6"
						:key="index"
						:class="[
							shake ? 'shake' : '',
							index < managerCode.length ? 'filled-dot' : 'dot',
						]"
					></div>
				</div>
				<div class="content-message">{{ text }}</div>
				<div class="btns">
					<div
						class="btn-wrap"
						v-for="e in btns"
						:key="e.value"
						@click="onClickBtn(e)"
					>
						<div
							:class="{
								'btn-active': e.selected && e.value !== BACK,
								btn: true,
								empty: e.value === EMPTY,
								back: e.value === BACK,
							}"
						>
							<img
								v-if="e.value === BACK"
								src="../assets/quantity_btn_space.png"
								alt="backspace"
							/>

							<span v-else-if="e.value !== EMPTY">{{ e.value }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MyFullHeightContainer from '@/components/FullHeightContainer.vue'

const EMPTY = 'EMPTY'
const BACK = 'BACK'

export default {
	name: 'MyValidManagerCodeModal',
	components: { MyFullHeightContainer },
	data() {
		return {
			isShow: this.$store.state.validManagerCodeDisplay,
			managerCode: '',
			btns: [
				{
					value: 1,
					selected: false,
				},
				{
					value: 2,
					selected: false,
				},
				{
					value: 3,
					selected: false,
				},
				{
					value: 4,
					selected: false,
				},
				{
					value: 5,
					selected: false,
				},
				{
					value: 6,
					selected: false,
				},
				{
					value: 7,
					selected: false,
				},
				{
					value: 8,
					selected: false,
				},
				{
					value: 9,
					selected: false,
				},
				{
					value: EMPTY,
					selected: false,
				},
				{
					value: 0,
					selected: false,
				},
				{
					value: BACK,
					selected: false,
				},
			],
			shake: false,
			text: '',
		}
	},
	computed: {
		EMPTY() {
			return EMPTY
		},
		BACK() {
			return BACK
		},
	},
	watch: {
		managerCode(newValue) {
			if (newValue.length === 6) {
				this.validateManagerCode()
			}
		},
	},
	methods: {
		onClickBtn(n) {
			const value = n.value
			if (typeof value === 'number') {
				if (this.managerCode.length < 6) {
					this.managerCode += value
				}
			} else if (value === EMPTY) {
				// Do nothing
			} else if (value === BACK) {
				this.managerCode = this.managerCode.slice(0, -1)
			}
			n.selected = true
			setTimeout(() => {
				n.selected = false
			}, 50)
		},
		async validateManagerCode() {
			const res = await this.$http.store.validManagerCode({
				managerCode: this.managerCode,
				...this.$getStoreId(),
			})
			if (res.status) {
				this.$store.state.validManagerCodeDisplay = false
				this.isShow = false
			} else {
				this.shake = true
				this.text = '識別碼錯誤!'
				setTimeout(() => {
					this.shake = false
				}, 1000)

				setTimeout(() => {
					this.text = ''
				}, 2000)

				this.managerCode = ''
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

.shake {
	animation: shake 0.5s;
	animation-iteration-count: 2;
}

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;
	background: rgb(33, 33, 33, 0.26);
	height: calc(var(--vh) * 100 - 80px);
	.keyboard {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: $darkTextColor;
		padding: 32px;
		width: 430px;
		max-width: 90%;
		border-radius: 8px;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	}
}

.input-title {
	width: 100%;
	padding-left: 12px;
	text-align: center;
	font-size: 30px;
	color: $lightTextColor;
	margin-bottom: 26px;
	padding-bottom: 8px;
	font-weight: bold;
	background: transparent;
	border-bottom: solid $lineColor 2px;
}

.dots {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	height: 20px;
}

.dot,
.filled-dot {
	width: 20px;
	height: 20px;
	border: 2px solid $com2Color;
	transition: 0.3s;
	margin: 0 6px;
	border-radius: 50%;
}

.filled-dot {
	background: $com2Color;
}

.content-message {
	display: flex;
	font-size: 18px;
	color: $activeColor;
	align-items: center;
	justify-content: center;
	min-height: 25px;
	margin-bottom: 10px;
}

.btns {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 auto;
	border-radius: 8px;
	padding: 0px 12px;
}

.btn-wrap {
	width: 33.3333%;
}

.btn:not(.empty, .back) {
	transition: 0.3s;
	margin: 0 auto 8px;
	border-radius: 8px;
	border: 2px solid $lineColor;
	color: $lightTextColor;
	background: $darkTextColor;
	font-size: 36px;
	font-weight: 400;
	width: calc(100% - 6px);
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:active {
		opacity: 0.3;
	}
}

.btn.back {
	transition: 0.3s;
	width: calc(100% - 6px);
	height: 60px;
	border-radius: 8px;
	border: 2px solid $lineColor;
	background: $darkTextColor;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:active {
		opacity: 0.3;
	}
	img {
		height: 24px;
		width: auto;
	}
}
</style>
