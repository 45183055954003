export default {
	namespaced: true,
	// modules: {}
	state: {
		onWSMessageStoreMeal: null,
		/** @type {BlueTooth | null} Printer001 */
		BTPosPrinter: null,
		BTPosPrinterSecond: null,
		/** @type {BlueTooth | null} DL-XXXX */
		BTKioskPrinter: null,
		BTTagPrinter: null,
		BTPosPrinterDevice: null,
		BTPosPrinterDeviceSecond: null,
		posPrinterStatus: "OFFLINE",
		posSecondPrinterStatus: "OFFLINE",
		tagPrinterStatus: "OFFLINE",
		thirdPartyDeliveryOrderHasChanged:false, // 外送平台訂單有無變動
		modifyOrderQueue: [], // 訂單變動通知彈窗的訂單佇列，預設為外送訂單
	},
	mutations: {
		setThirdPartyDeliveryOrderHasChanged($, val) {
			$.thirdPartyDeliveryOrderHasChanged = val
		},
		// 新增訂單(在佇列後方)
		enqueueModifyOrder(state, order) {
			state.modifyOrderQueue.push(order);
		},
		// 移除訂單(從佇列前方開始移除)
		dequeueModifyOrder(state) {
			state.modifyOrderQueue.splice(0, 1);
		}
	},
	actions: {
		setBTPosPrinter($, val) {
			$.BTPosPrinter = val
		},
		setBTPosPrinterSecond($, val) {
			$.BTPosPrinterSecond = val
		},
		setBTKioskPrinter($, val) {
			$.BTKioskPrinter = val
		},
	},
}
