export default {
	namespaced: true,
	// modules: {}
	state: {
		isCollapsedHeader: false,
		drawer: true,
		miniVariant: false,
		temporary: false,
		expandOnHover: false,
		disabledResize: false,
	},
	mutations: {
		toggleCollapsedHeader(state) {
			state.isCollapsedHeader = !state.isCollapsedHeader
		},
		setDrawer($, val) {
			return ($.drawer = val)
		},
		setMiniVariant($, val) {
			return ($.miniVariant = val)
		},
		setTemporary($, val) {
			return ($.temporary = val)
		},
		setExpandOnHover($, val) {
			return ($.expandOnHover = val)
		},
		setDisabledResize($, val) {
			return ($.disabledResize = val)
		},
		changeNavigationOption($, val) {
			if (window.innerWidth > 1024) {
				const miniVariant = $.miniVariant

				this.commit('home/setMiniVariant', val)
				this.commit('home/setExpandOnHover', val)
			} else {
				this.commit('home/setDrawer', val)
			}
		},
	},
	actions: {},
}
