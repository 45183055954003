/**
 * 檢查捐贈碼：
 * 1.須為3-7碼
 * 2.由數字0-9組成
 *
 * @param donationNumber 捐贈碼，型別需為String
 */
export function checkDonationNumber(donationNumber) {
    const pattern = /^\d{3,7}$/;
    return  pattern.test(donationNumber);
}
