import service from './service'

export default {
	// 取得餐點列表(依分類)
	getMenu: params => service.get(`/back/pos/getMenu`, { params }),

	// 取得一筆餐點資訊
	getItem: params => service.get(`back/pos/getItemDetail`, { params }),

	// 新增訂單
	addOrder: params => service.post(`back/pos/addOrder`, params),

	// 列印購物車
	printTag: params => service.post(`back/pos/printTag`, params),

	// 取得餐點庫存資訊
	getItemStock: params => service.get(`back/pos/getItemStock`, { params }),

	// 修改餐點庫存資訊
	updateItemStock: params => service.put(`back/pos/updateItemStock`, params),
}
