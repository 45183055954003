<template>
	<MyFormRow
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:labelPosition="labelPosition"
		:color="color"
	>
		<div class="custom-checkbox--once" v-if="items.length === 0">
			<v-checkbox
				class="custom-checkbox"
				v-model="_onceValue"
				:disabled="disabled"
				:rules="rules"
			>
				<template #label>
					<slot name="label">{{ checkboxLabel }}</slot>
				</template>
				<template #append>
					<slot name="append"></slot>
				</template>
			</v-checkbox>
			<div class="content">
				<slot name="content"></slot>
			</div>
		</div>
		<div class="custom-checkbox-wrap--mulitple" :style="wrapStyle" v-else>
			<div
				class="custom-checkbox--mulitple"
				v-for="(item, index) in items"
				:key="item.value"
			>
				<v-checkbox
					class="custom-checkbox"
					v-model="item.checked"
					:label="item[defaultLabelKey]"
					:disabled="disabled || item.disabled || item.temporaryDisabled"
					:rules="rules"
					input-value="itemId"
					@change="change(index)"
				>
					<template #label>
						<slot :name="'label' + index" :item="item" :index="index">
							<slot name="label" :item="item" :index="index">{{
								item[defaultLabelKey]
							}}</slot>
						</slot>
					</template>
					<template #append>
						<slot :name="'append' + index" :item="item" :index="index">
							<slot name="append" :item="item" :index="index"></slot>
						</slot>
					</template>
				</v-checkbox>
				<div class="content">
					<slot name="content" :item="item" :index="index"></slot>
				</div>
			</div>
		</div>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyCheckbox',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: Boolean | Array,
			default: false,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		// 有值就多個，value 回傳數組
		// label, checked?, disabled?
		items: {
			required: false,
			type: Array,
			default: () => [],
		},
		// 單個的 checkbox label
		checkboxLabel: {
			required: false,
			type: String,
		},
		// 多個的 checkbox 們是否要換行
		isWrap: {
			required: false,
			type: Boolean,
			default: true,
		},
		defaultLabelKey: {
			required: false,
			type: String,
			default: 'label',
		},
		// 是否禁用，設定 true 單個及多個全部進用，多個可用 item.disabled 禁用
		disabled: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		itemChange: {
			required: false,
			type: Function,
			default: () => true,
		},
	},
	data() {
		return {
			index: '',
		}
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
		_onceValue: {
			get() {
				return this.value
			},
			set(checked) {
				this.$emit('updateValue', checked)
				this.$emit('onChange', { checked })
			},
		},
		wrapStyle() {
			return { 'flex-direction': this.isWrap ? 'column' : 'row' }
		},
	},
	watch: {
		items: {
			deep: true,
			handler(newItems) {
				const newCheckeds = newItems.map(item => item.checked || false)
				this.$emit('updateValue', newCheckeds)
				this.$emit('onChange', { checked: newCheckeds, index: this.index })
			},
		},
	},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		change(index) {
			this.index = index
		},
		getCheckedList(items) {
			const _items = items ?? this.items
			return _items.map(item => item.checked || false)
		},
		updateValue(items) {
			this.$emit('updateValue', this.getCheckedList(items))
		},
	},
}
</script>
<style lang="scss" scoped>
.custom-checkbox-wrap--mulitple {
	width: 100%;
	::v-deep {
		.v-input--selection-controls__input {
			.mdi-checkbox-marked {
				&::before {
					content: '';
					background: url('../assets/checkBox.png');
					width: 24px;
					height: 24px;
				}
			}
			.mdi-checkbox-blank-outline {
				&::before {
					content: '';
					border-radius: 4px;
					border: solid 2px $selectColor;
					opacity: 0.5;
					width: 24px;
					height: 24px;
				}
				&.error--text {
					&::before {
						opacity: 1;
						border: solid 2px $activeColor;
					}
				}
			}
		}

		.custom-checkbox--mulitple {
			.v-input {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 12px;
				&.v-input--is-disabled {
					opacity: 0.3;
				}
				.v-input__control {
					max-width: 80%;
				}
				.v-input__slot {
					margin-bottom: 0px;
					.v-label {
						margin-left: 12px;
						font-size: 1rem;
						color: $lightTextColor;
					}
				}
				.v-input__append-outer {
				}
			}
			&:last-child {
				.v-input {
					//margin-bottom: 0;
				}
			}
		}
	}
}

.custom-checkbox--mulitple {
}

.content {
}
</style>
