<template>
	<div ref="containerRef">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'MyFullHeightContainer',
	data() {
		return {
			trueTimes: 0,
			prevTop: -999,
			isTrueTop: false,
			checkTopInterval: null,
		}
	},
	methods: {},
	mounted() {},
	beforeDestroy() {},
}
</script>
