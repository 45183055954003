<template>
	<header>
		<div
				ref="titleWrapRef"
				:class="{ notShowDrawer: !manager }"
				class="page-title-wrap"
				:style="{
				width: `calc(100% - ${wrapMinusWidth}px)`,
				transform: `translateY(${
					$store.state.home.isCollapsedHeader && manager ? -100 : 0
				}%)`,
			}"
		>
			<div
					v-if="manager"
					class="collapse"
					:style="{
					transform: `rotate(${
						$store.state.home.isCollapsedHeader && manager ? 180 : 0
					}deg)`,
				}"
					@click="$store.commit('home/toggleCollapsedHeader')"
			/>
			<v-icon
					class="menu"
					v-if="manager"
					@click="
					$store.commit(
						'home/changeNavigationOption',
						windowWidth ? !miniVariant : !drawer,
					)
				"
			>
				mdi-menu
			</v-icon>
			<router-link
					class="page-title"
					style="cursor: pointer"
					:to="posSwitch ? '/store-pos' : '/order-manage/socket'"
			>
				<img
						v-if="!manager"
						src="../assets/header_img_logo.png"
						alt=""
						class="page-title__logo"
				/>
				<img
						v-if="manager && !windowWidth"
						src="../assets/logoIconBlack.png"
						alt=""
						class="page-title__logo"
				/>
				<div class="left-box-checkbox" v-if="isMobile && !manager">
					<span style="color: #fff">手機點餐</span>
					<MyNewSwitch
							:value="shopOrderStatus"
							@input="setShopOrderStatus"
					></MyNewSwitch>
				</div>
			</router-link>
			
			<div v-if="isShowTimer" class="timer">
				{{ currentTime }}
			</div>
			
			<div class="left-box">
				<div
						class="storeName"
						style="cursor: pointer"
						@click="$router.replace('/account-manage')"
						v-if="!isMobile"
				>
					<span> {{ storeName }}</span>
				</div>
				
				<div
						@click="$router.replace('/account-manage')"
						v-if="!isMobile && manager"
						style="font-weight: bold; width: 180px"
				>
					<span> {{ storeName }}</span>
				</div>
				
				<div class="left-box-checkbox" v-if="isShowGoFront && !isMobile && isMobileOrderSystemModule">
					<span>手機點餐</span>
					<MyNewSwitch
							:value="shopOrderStatus"
							@input="setShopOrderStatus"
					></MyNewSwitch>
				</div>
				
				<div
						class="bluetooth"
						@click="handleBluetooth"
						v-if="
						isShowGoFront &&
						(!isMac || isReactNative) &&
						($store.state.storage.hasPos ||
							($store.state.storage.hasTag && isReactNative))
					"
				>
					<img src="../assets/header_btn_bluetooth.png" alt=""/>
					<div
						class="bluetooth-box"
						v-if="
							$store.state.storage.hasPos && $store.state.storage.posMac
						"
					>
						<div class="bluetooth-link">
							出單機
							<div
									:class="{
										'link-dot': true,
										'connecting' : posPrinterStatus === 'CONNECTING'
									}"
									:style="{
									background: posPrinterStatusColor
				
								}"
							/>
						</div>
					</div>
					<div
						class="bluetooth-box"
						v-if="
							$store.state.storage.hasPos && $store.state.storage.posMacSecond
						"
					>
						<div class="bluetooth-link">
							出單機
							<div
									:class="{
										'link-dot': true,
										'connecting' : posSecondPrinterStatus === 'CONNECTING'
									}"
									:style="{
									background: posSecondPrinterStatusColor
					
								}"
							/>
						</div>
<!--						<div class="bluetooth-link">-->
<!--							出單機 2-->
<!--							<div-->
<!--									:class="{-->
<!--										'link-dot': true,-->
<!--										'connecting' : tagPrinterStatus === 'CONNECTING'-->
<!--									}"-->
<!--									:style="{-->
<!--									background: tagPrinterStatusColor-->
<!--								-->
<!--								}"-->
<!--							/>-->
<!--						</div>-->
					</div>
					<div class="bluetooth-box">
						<div
							v-if="$store.state.storage.hasTag"
							class="bluetooth-link"
						>
							標籤機
							<span
									:class="{
										'link-dot': true,
										'connecting' :tagPrinterStatus === 'CONNECTING'
									}"
									:style="{
										background: tagPrinterStatusColor
								}"
							/>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: flex" v-if="!manager && isMobile">
				<v-menu
						content-class="is-mobile"
						transition="slide-y-transition"
						offset-y
						bottom
						:nudge-top="-14.5"
						:close-on-content-click="false"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-icon class="menu" color="white" v-on="on" v-bind="attrs" large>
							mdi-menu
						</v-icon>
					</template>
					<v-card>
						<v-list :color="'#525252'" dark style="width: 100vw">
							<v-list-item>
								<v-list-item-title class="font-weight-bold text-h6">
									{{ storeName }}
								</v-list-item-title>
							</v-list-item>
							<v-divider #dark/>
							<v-list-item :to="`/order-manage/socket`">
								<v-list-item-title class="font-weight-bold text-h6"
								>訂單管理
								</v-list-item-title
								>
							</v-list-item>
							<v-divider #dark/>
							<v-divider #dark/>
							<v-list-item :to="`/report/order-list`">
								<v-list-item-title class="font-weight-bold text-h6"
								>報表
								</v-list-item-title
								>
							</v-list-item>
							<v-divider #dark/>
							<v-list-item
									active-class="white&#45;&#45;text"
									:to="`/menu-manage/meals`"
							>
								<v-list-item-title class="font-weight-bold text-h6"
								>菜單管理
								</v-list-item-title
								>
							</v-list-item>
							<v-list-item :to="`/account-manage/store-set`">
								<v-list-item-title class="font-weight-bold text-h6"
								>店家管理
								</v-list-item-title
								>
							</v-list-item>
							<v-divider #dark/>
							<v-list-item @click="logoutModal = true">
								<v-list-item-title class="font-weight-bold text-h6"
								>登出
								</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</v-card>
				</v-menu>
			</div>
			
			<div class="control" v-if="!isMobile">
				<div
						class="div-item"
						@click="openAnnouncementDisplayModal"
						v-if="!manager && announcementDisplayList.length > 0"
				>
					<!--					-->
					
					<img src="../assets/header_btn_announcement.png" alt=""/>
					<div class="red-tag"/>
					<span class="div-item-name">公告</span>
				</div>



				<router-link
						class="div-item"
						v-if="!manager && posSwitch && showPosSwitch"
						:to="`/store-pos`"
						active-class="active"
						　
				>
					<img
							class="unActive-img"
							src="../assets/header_btn_order_active-1.png"
							alt=""
					/>
					<img
							class="active-img"
							src="../assets/header_btn_order_active-3.png"
							alt=""
					/>
					<span class="div-item-name">點餐</span>
				</router-link>
				
				<router-link
						class="div-item"
						v-if="!manager"
						:to="`/order-manage/socket`"
						active-class="active"
				>
					<img
							class="unActive-img"
							src="../assets/header_btn_orderform_active-1.png"
							alt=""
					/>
					<img
							class="active-img"
							src="../assets/header_btn_orderform_active-3.png"
							alt=""
					/>
					<span class="div-item-name">訂單</span>
					<div v-if="orderManagerRedTag" class="red-tag"/>
				</router-link>
				<router-link
						class="div-item"
						:class="{ active: $route.path.slice(0, 7) === '/report' }"
						v-if="!manager"
						:to="`/report/order-list`"
				>
					<img
							class="unActive-img"
							src="../assets/header_btn_report_active-1.png"
							alt=""
					/>
					<img
							class="active-img"
							src="../assets/header_btn_report_active-3.png"
							alt=""
					/>
					<span class="div-item-name">報表</span>
				</router-link>
				
				<router-link
						class="div-item"
						v-if="!manager"
						:class="{
						active:
							$route.path.slice(0, 12) === '/menu-manage' ||
							$route.path.slice(0, 15) === '/account-manage',
					}"
						:to="`/menu-manage/meals`"
						active-class="active"
				>
					<img
							class="unActive-img"
							src="../assets/header_btn_set_active-1.png"
							alt=""
					/>
					<img
							class="active-img"
							src="../assets/header_btn_set_active-3.png"
							alt=""
					/>
					<span class="div-item-name">設定</span>
				</router-link>
				<div class="flex-row" style="display: flex; align-items: center">
					<div
							class="go-front kiosk"
							@click="onGoFront(true)"
							v-show="checkLoginRole && isShowGoFront && manager"
					>
						出單點餐
					</div>
					<div
							class="go-front"
							@click="onGoFront(false)"
							v-show="checkLoginRole && isShowGoFront && manager"
					>
						手機點餐
					</div>
					<div v-if="manager" class="logout" @click="logoutModal = true">
						登出
					</div>
					<div
							class="fullscreen"
							@click="onToggleFullscreen"
							v-if="$store.state.isShowGoFront && !isMac && !isReactNative"
					>
						<img
								src="../assets/header_btn_zoomin.png"
								alt=""
								v-if="isFullscreen"
						/>
						<img src="../assets/header_btn_zoomout.png" alt="" v-else/>
					</div>
				</div>
			</div>
			<div class="control" v-if="manager && isMobile">
				<div class="flex-row" style="display: flex; align-items: center">
					<div v-if="manager" class="logout" @click="logoutModal = true">
						登出
					</div>
				</div>
			</div>
		</div>
		<div ref="wrapPaddingRef" :style="{ paddingTop: `${wrapPaddingTop}px` }"/>
		
		<MyModal
				v-model="bluetoothModal"
				@onSubmit="bluetoothModal = false"
				title=""
				:btnSize="false"
				:cancel-name="null"
				submitName="確認"
		>
			<div
					v-if="!isConnecting"
					class="gray-button"
					color="grayColor"
					style="font-size: 1rem"
					@click="retryBlueTooth"
			>
				<v-icon>mdi-bluetooth</v-icon>
				點此進行藍芽連線
			</div>
			
			<div
					v-if="isConnecting"
					class="gray-button"
					color="grayColor"
					style="font-size: 1rem"
			>
				<v-icon>mdi-bluetooth</v-icon>
				藍芽連線中...
			</div>
			
			<div
					v-if="$store.state.storage.hasPos && $store.state.storage.posDeviceId"
					class="machine-info"
			>
				
				<div class="info-text">
					出單機
					<p class="orange-circle">1</p>
					<div>藍芽名稱：{{ this.$store.state.storage.posDeviceId }}</div>
					<div class="status-container">
						狀態：
						<div
								class="link-dot"
								:style="{
									background:
										BTPosPrinter && $store.state.socket.isConnected
											? '#68f740'
											: '#F00',
								}"
						/>
						<div
								:style="{
									color:
										BTPosPrinter && $store.state.socket.isConnected
											? 'green'
											: 'red',
								}"
						>
							{{
								BTPosPrinter && $store.state.socket.isConnected
										? '已連線'
										: '未連線'
							}}
						</div>
					</div>
				</div>
			</div>
			<div
					v-if="$store.state.storage.hasPos && $store.state.storage.posMacSecond"
					class="machine-info"
			>
				<div class="info-text">
					<div>
						出單機
						<p class="orange-circle">2</p>
					</div>
					<div>藍芽名稱：{{ this.$store.state.storage.posDeviceIdSecond }}</div>
					<div class="status-container">
						狀態：
						<div
								class="link-dot"
								:style="{
								background:
									BTPosPrinterSecond && $store.state.socket.isConnected
										? '#68f740'
										: '#F00',
							}"
						/>
						<div
								:style="{
								color:
									BTPosPrinterSecond && $store.state.socket.isConnected
										? 'green'
										: 'red',
							}"
						>
							{{
								BTPosPrinterSecond && $store.state.socket.isConnected
										? '已連線'
										: '未連線'
							}}
						</div>
					</div>
				</div>
			</div>
			<div
					v-if="isReactNative && $store.state.storage.hasTag"
					class="machine-info"
			>
				<div class="info-text">
					<div>標籤機</div>
					<div>藍芽名稱：{{ this.$store.state.storage.tagDeviceId }}</div>
					<div class="status-container">
						狀態：
						<div
								class="link-dot"
								:style="{
								background:
									BTTagPrinter && $store.state.socket.isConnected
										? '#68f740'
										: '#F00',
							}"
						/>
						
						<div
								:style="{
								color:
									BTTagPrinter && $store.state.socket.isConnected
										? 'green'
										: 'red',
							}"
						>
							{{
								BTTagPrinter && $store.state.socket.isConnected
										? '已連線'
										: '未連線'
							}}
						</div>
					</div>
				</div>
			</div>
		</MyModal>
		
		<MyModal
				v-model="logoutModal"
				@onCancel="logoutModal = false"
				@onSubmit="onLogout"
				title=""
				submitName="是"
				cancelName="否"
				:btnSize="false"
		>
			<MyDeleteText title="登出">確定要登出嗎？</MyDeleteText>
		</MyModal>
	</header>
</template>
<script>
import toggleFullscreen from '@/utils/toggleFullscreen'

export default {
	name: 'MyPageTitle',
	props: {
		items: {
			required: false,
			type: Array,
		},
	},
	data() {
		return {
			isFullscreen: false,
			titleWrapRefHeight: 0,
			logoutModal: false,
			shopOrderStatus: this.$store.state.storage?.shopOrderStatus,
			currentTime: this.$formatDate(new Date(), 'HH:mm'),
			orientation: '', //螢幕方向
			bluetoothModal: false,
			isConnecting: false,
			showPosSwitch: false,
		}
	},
	created() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
		this.checkAndUpdateAnnouncements()
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},
	computed: {
		isShowTimer() {
			return !this.manager && this.orientation === 'Horizontal'
		},
		posSwitch() {
			return this.$store.state.storage.systemModule.posOrder
		},
		manager() {
			return this.$store.state.storage.manager
		},
		announcementDisplayList() {
			return this.$store.state.storage.announcementDisplayList
		},
		storeName() {
			return this.$store.state.storage.storeName
		},
		wrapPaddingTop() {
			return this.$store.state.home.isCollapsedHeader
					? 12
					: this.manager
							? this.titleWrapRefHeight + 12
							: this.titleWrapRefHeight
		},
		miniVariant() {
			return this.$store.state.home.miniVariant
		},
		windowWidth() {
			return window.innerWidth > 1024
		},
		drawer() {
			return this.$store.state.home.drawer
		},
		temporary() {
			return this.$store.state.home.temporary
		},
		wrapMinusWidth() {
			return !this.temporary
					? !this.manager
							? 0
							: this.miniVariant
									? 56
									: 256
					: 0
		},
		storeId() {
			return this.$store.state.storage.storeId
		},
		isShowGoFront() {
			return this.$store.state.isShowGoFront
		},
		isReactNative() {
			return this.$isReactNative()
		},
		BTPosPrinter() {
			return this.$store.state.order.BTPosPrinter
		},
		BTPosPrinterSecond() {
			return this.$store.state.order.BTPosPrinterSecond
		},
		BTTagPrinter() {
			return this.$store.state.order.BTTagPrinter
		},
		
		
		posPrinterStatus() {
			return this.$store.state.order.posPrinterStatus
		},
		posSecondPrinterStatus() {
			return this.$store.state.order.posSecondPrinterStatus
		},
		tagPrinterStatus() {
			return this.$store.state.order.tagPrinterStatus
		},
		
		posPrinterStatusColor() {
			if (!this.$store.state.socket.isConnected) {
				return this.statusColor("OFFLINE")
			}
			
			return this.statusColor(this.$store.state.order.posPrinterStatus)
		},
		posSecondPrinterStatusColor() {
			if (!this.$store.state.socket.isConnected) {
				return this.statusColor("OFFLINE")
			}
			
			return this.statusColor(this.$store.state.order.posSecondPrinterStatus)
		}
		,
		tagPrinterStatusColor() {
			if (!this.$store.state.socket.isConnected) {
				return this.statusColor("OFFLINE")
			}
			return this.statusColor(this.$store.state.order.tagPrinterStatus)
		}
		,
		
		
		checkLoginRole() {
			return this.$store.state.storage.loginRole !== 'USER_MANAGER'
					? false
					: this.$store.state.storage.loginRole === 'USER_MANAGER' && this.storeId
		}
		,
		isMac() {
			return /macintosh|mac os x/i.test(navigator.userAgent)
		}
		,
		isMobile() {
			return this.$getMobile()
		}
		,
		isMobileOrderSystemModule() {
			return this.$store.state.storage.systemModule.mobileOrder
		}
		,
		orderManagerRedTag() {
			return this.$store.state.orderManagerRedTag
		}
		,
	},
	watch: {
		'$store.state.storage.shopOrderStatus'() {
			this.shopOrderStatus = this.$store.state.storage.shopOrderStatus
		},
	},
	mounted() {
		this.titleWrapRefHeight = this.$refs.titleWrapRef?.clientHeight || 0
		this.isFullscreen = document.fullscreenElement
		setTimeout(() => {
			if (this.$refs.wrapPaddingRef) {
				this.$refs.wrapPaddingRef.style.transition = 'padding-top .6s ease'
			}
		}, 100)
		document.addEventListener('fullscreenchange', this.onFullscreenChange)
		window.addEventListener('resize', this.onSetTitleWrapRefHeight)
		this.updateTime()
		this.timer = setInterval(this.updateTime, 1000)
	},
	beforeDestroy() {
		document.removeEventListener('fullscreenchange', this.onFullscreenChange)
		window.removeEventListener('resize', this.onSetTitleWrapRefHeight)
		clearInterval(this.timer)
	},
	methods: {
		statusColor(status) {
			if (status === 'ONLINE') {
				return '#68f740'
			} else if (status === 'OFFLINE') {
				return '#F00'
			} else if (status === 'CONNECTING') {
				return '#ffa600'
			}
			return '#F00'
		}
		,
		checkAndUpdateAnnouncements() {
			if (this.isReactNative) {
				this.$http.system
						.get(
								this.isMac
										? {paramId: 'APP_VERSION_IOS'}
										: {paramId: 'APP_VERSION_ANDROID'},
						)
						.then(response => {
							const onlineVersion = response.data
							const currentVersion = this.isMac
									? this.$store.state.storage.iosVersion
									: this.$store.state.storage.androidVersion
							const newAnnouncementDisplayList = []
							
							this.announcementDisplayList.forEach(announcement => {
								if (
										announcement.type === 'APP_UPDATE' &&
										onlineVersion === currentVersion
								) {
									this.$http.announcement.updateAnnouncementDisplay({
										announcementId: announcement.id,
										display: false,
										storeId: this.storeId,
									})
								} else {
									newAnnouncementDisplayList.push(announcement)
								}
							})
							
							this.$store.commit(
									'storage/setAnnouncementDisplayList',
									newAnnouncementDisplayList,
							)
							if (this.$store.state.storage.firstShowAnnouncement) {
								if (this.announcementDisplayList.length) {
									this.$store.commit('storage/setAnnouncementDisplayShow')
								}
								this.$store.commit('storage/setFirstShowAnnouncement', false)
							}
						})
			}
		},
		handleResize() {
			const width = window.innerWidth
			const height = window.innerHeight
			this.showPosSwitch = width > height
			this.orientation = width > height ? 'Horizontal' : 'Straight'
		},
		updateTime() {
			this.currentTime = this.$formatDate(new Date(), 'HH:mm')
		},
		onSetTitleWrapRefHeight() {
			const titleWrapRefHeight = this.$refs.titleWrapRef?.clientHeight || 0
			if (this.titleWrapRefHeight !== titleWrapRefHeight) {
				this.titleWrapRefHeight = titleWrapRefHeight
			}
		},
		openAnnouncementDisplayModal() {
			this.$store.commit('storage/setAnnouncementDisplayShow')
		},
		onFullscreenChange() {
			this.isFullscreen = document.fullscreenElement
		},
		onLogout() {
			this.$store.dispatch('logout')
		},
		onGoFront(isKiosk) {
			const storeId =
					this.$store.state.storage.storeId[0] ||
					this.$store.state.storage.storeId
			const winRef = window.open('', '_blank')
			this.$http
					.getQRcodeHash({storeId})
					.then(res => {
						const qrCodeHash = res.data.qrCodeHash
						const frontStageURL = res.data.frontStageURL
						const kioskQuery = isKiosk ? `?kiosk=${qrCodeHash}` : ''
						if (isKiosk) {
							winRef.location = `${frontStageURL}/${res.data.qrCodeHash}/Refresh${kioskQuery}`
						} else {
							winRef.location = `${frontStageURL}/${res.data.qrCodeHash}/Store`
						}
					})
					.catch(err => {
						this.$store.commit('showMessage', {
							text: err,
							color: 'error',
						})
					})
		},
		onToggleFullscreen() {
			toggleFullscreen()
		},
		setShopOrderStatus() {
			this.shopOrderStatus = !this.shopOrderStatus
			this.$http.store
					.updateStoreSetting({
						storeId: this.storeId,
						setting: 'MOBILE_ORDER_SWITCH', //MOBILE_ORDER_SWITCH 、 BLUETOOTH_CONNECTION_SWITCH
						value: this.shopOrderStatus,
					})
					.then(res => {
						this.$store.commit('storage/setShopOrderStatus', this.shopOrderStatus)
					})
					.catch(err => {
						this.$store.commit('showMessage', {
							text: err,
							color: 'error',
						})
						// const errSwitch = !this.shopOrderStatus
						this.shopOrderStatus = !this.shopOrderStatus
						// this.$store.commit('storage/setHasPos', errSwitch)
					})
		},
		async handleBluetooth() {
			if (
					this.$store.state.storage.hasPos &&
					this.$store.state.storage.posMacSecond
			) {
				this.bluetoothModal = true
			} else {
				await this.onBindBlueTooth(false)
			}
		},
		async onBindBlueTooth(second) {
			if (this.isReactNative) {
				window.ReactNativeWebView?.postMessage(
						JSON.stringify({
							type: 'blueTooth',
							data: {
								posMac:
										this.$store.state.storage.hasPos &&
										this.$store.state.storage.posMac,
								posMacSecond:
										this.$store.state.storage.hasPos &&
										this.$store.state.storage.posMacSecond,
								tagMac:
										this.$store.state.storage.hasTag &&
										this.$store.state.storage.tagMac,
								posDeviceId:
										this.$store.state.storage.hasPos &&
										this.$store.state.storage.posDeviceId,
								posDeviceIdSecond:
										this.$store.state.storage.hasPos &&
										this.$store.state.storage.posDeviceIdSecond,
								tagDeviceId:
										this.$store.state.storage.hasTag &&
										this.$store.state.storage.tagDeviceId,
							},
						}),
				)
				return
			}
			try {
				const device = await navigator.bluetooth.requestDevice({
					filters: [{services: [0x18f0]}],
				})
				try {
					const server = await device.gatt.connect()
					const service = await server.getPrimaryService(0x18f0)
					const characteristic = await service.getCharacteristic(0x2af1)
					this.$store.commit('showMessage', `[${device.name}] 出單機連結成功`)
					
					// 本地測試出單機，註解上面if-else，然後打開以下2行註解
					if (second === true) {
						this.$store.state.order.BTPosPrinterSecond = characteristic
						this.$store.state.order.BTPosPrinterDeviceSecond = device
					} else if (second === false) {
						this.$store.state.order.BTPosPrinter = characteristic
						this.$store.state.order.BTPosPrinterDevice = device
					} else {
						this.$store.state.order.BTTagPrinter = characteristic
						this.$store.state.order.BTTagPrinterDevice = device
					}
					// this.$store.state.order.BTPosPrinter = characteristic
					// this.$store.state.order.BTPosPrinterDevice = device
				} catch (err) {
					this.$store.commit('showMessage', {
						text: `[${device.name}] ${err}`,
						color: 'error',
					})
					// this.$store.commit('showMessage', {
					// 	text: `藍芽連接失敗`,
					// 	color: 'error',
					// }) //跟樂樂討論後顯示中文
				}
			} catch (err) {
				this.$store.commit('showMessage', {
					text: `藍芽連接失敗`,
					color: 'error',
				}) //跟樂樂討論後顯示中文
				// this.$store.commit('showMessage', {text: `Argh! ${err}`, color: 'error'})//跟樂樂討論後不顯示
			} finally {
				await this.$store.dispatch('socket/reconnection')
			}
		},
		disconnectedBlueTooth(second) {
			if (this.isReactNative) {
				if (second === true) {
					window.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: 'disconnect',
								data: 'posSecond',
							}),
					)
				} else if (second === false) {
					window.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: 'disconnect',
								data: 'pos',
							}),
					)
				} else {
					window.ReactNativeWebView?.postMessage(
							JSON.stringify({
								type: 'disconnect',
								data: 'tag',
							}),
					)
				}
				return
			}
			if (second === true) {
				this.$store.state.order.BTPosPrinterDeviceSecond?.gatt.disconnect()
			} else if (second === false) {
				this.$store.state.order.BTPosPrinterDevice?.gatt.disconnect()
			} else {
				this.$store.state.order.BTTagPrinterDevice?.gatt.disconnect()
			}
		},
		retryBlueTooth() {
			this.isConnecting = true
			this.onBindBlueTooth(false)
			this.onBindBlueTooth(true)
			this.onBindBlueTooth(null)
			setTimeout(() => {
				this.isConnecting = false
			}, 10000)
		},
	},
}
</script>
<style lang="scss" scoped>
%common-btn {
	font-size: 0.8rem;
	padding: 5px 15px;
	border-radius: 5px;
	margin-right: 6px;
	cursor: pointer;
	font-weight: 900;
	transition: 0.4s ease;
	@include rwd(765) {
		font-size: 0.75rem;
		padding: 4px 8px;
	}
}

.page-title-wrap {
	background: #fff;
	color: #333;
	white-space: nowrap;
	padding: 6px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10;
	transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1), transform 0.6s ease;
	@include cardShadow;
	
	@include rwd(765) {
		white-space: nowrap;
		padding: 12px 10px !important;
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.collapse {
	position: absolute;
	left: 12px;
	bottom: 0;
	border-left: 12px solid;
	border-right: 12px solid;
	border-top: 0 solid;
	border-color: transparent;
	border-bottom: 12px solid #1976d2;
	cursor: pointer;
	transform-origin: bottom center;
	transition: transform 0.3s ease;
	z-index: 1;
}

.page-title {
	font-size: 1.125rem;
	font-weight: 900;
	position: relative;
	display: flex;
	align-items: center;
	
	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	&__logo {
		height: calc(10px + 1.25rem);
	}
	
	&-set {
		transform: translateY(3px);
		margin-left: 2px;
	}
	
	@include rwd(400) {
		padding-right: 4px;
	}
}

.menu {
	margin-right: 10px;
	transform: translateY(-1px);
	display: none;
	@include rwd(1024) {
		display: inline-flex;
	}
}

.left-box {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: auto;
	
	.storeName {
		display: none;
	}
	
	.breadcrumbs {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: 900;
	}
	
	.link {
		text-decoration: none;
		font-size: 0.8rem;
		
		&:not(:first-child) {
			&::before {
				content: '/';
				margin: 0 5px;
				color: rgba(0, 0, 0, 0.38);
			}
		}
		
		&:last-child {
			color: rgba(0, 0, 0, 0.38);
			cursor: default;
		}
	}
	
	&-checkbox {
		display: flex;
		align-items: center;
		border-left: solid 1px rgba(255, 255, 255, 0.15);
		padding-left: calc(24px - 0.5rem);
		font-size: calc(10px + 0.5rem);
		
		span {
			margin-right: 8px;
		}
		
		@include rwd(1200) {
			padding-left: calc(18px - 0.5rem);
			flex-direction: column;
			font-size: calc(8px + 0.5rem);
			span {
				margin-right: 0px;
			}
		}
		
		::v-deep {
			.custom-form-row {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				padding-top: 2px;
				height: 27px;
				
				.inline-form__label {
					margin-top: 0;
					padding-top: 0;
				}
			}
		}
	}
	
	@include rwd(765) {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.breadcrumbs {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 900;
}

.notShowDrawer {
	padding-left: 25px;
	padding-right: 12px;
	background: $headerColor;
	color: #fff;
	height: 80px;
	@include rwd(1000) {
		padding-left: 15px;
	}
	
	.page-title__logo {
		height: calc(64px - 1rem);
		@include rwd(400) {
			height: calc(32px);
			margin-right: 5px;
		}
	}
	
	.timer {
		padding: 0.5rem;
		color: white;
		font-size: calc(15px + 0.25rem);
	}
	
	.storeName {
		display: block;
		padding: 9px 12px;
		border-radius: 8px;
		background: $lightTextColor;
		margin-right: calc(32px - 1rem);
		font-weight: bold;
		font-size: calc(15px + 0.15rem);
		width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		@include rwd(1365) {
			display: none;
		}
	}
	
	.breadcrumbs {
		.link {
			font-size: 0.95rem;
			
			&:not(:first-child) {
				color: #fff;
				
				&::before {
					color: #fff;
				}
			}
		}
	}
	
	::v-deep {
		.inline-form__label {
			color: #fff;
		}
	}
	
	.fullscreen {
		height: 48px;
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		border: solid rgba(255, 255, 255, 0.3) 2px;
		
		img {
			height: 24px;
			width: 24px;
		}
	}
	
	.logout {
		color: #fff !important;
		opacity: 0.9;
		background: rgba(255, 255, 255, 0.35);
	}
	
	.control {
		display: flex;
		align-items: center;
		@include rwd(765) {
			position: absolute;
			right: 25px;
			top: 10.5px;
		}
		@include rwd(425) {
			position: relative;
			right: 0;
			top: 0;
			margin: 6px 0 0 32px;
		}
		
		.div-item {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90px;
			height: 48px;
			cursor: pointer;
			position: relative;
			margin-right: 10px;
			border-radius: 8px;
			color: $darkTextColor;
			
			> img {
				width: 32px;
				height: 32px;
			}
			
			.active-img {
				display: none;
			}
			
			.unActive-img {
				display: block;
			}
			
			&-name {
				font-size: calc(12px + 0.25rem);
				margin-left: 6px;
				font-weight: bold;
			}
			
			&.active {
				background: $com1Color;
				color: $lightTextColor;
				
				.active-img {
					display: block;
				}
				
				.unActive-img {
					display: none;
				}
			}
			
			.red-tag {
				position: absolute;
				left: 30px;
				top: 12px;
				background: $activeColor;
				height: 8px;
				width: 8px;
				border-radius: 15px;
			}
			
			@include rwd(1200) {
				width: 45px;
				.div-item-name {
					display: none;
				}
			}
		}
	}
}

.gray-button {
	color: $lightTextColor;
	background: $grayColor;
	padding: 10px;
	border: none;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 25px;
}

.gray-button img {
	width: 20px;
	height: 20px;
}

.machine-info {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 20px;
	margin-top: 20px;
	margin-left: 80px;
	color: $lightTextColor;
	
	.orange-circle {
		background-color: $com1Color;
		border-radius: 80%;
		display: inline-block;
		padding: 3px 10px;
	}
	p.orange-circle{
		margin: 0;
	}
	.info-text {
		color: $lightTextColor;
		
		.status-container {
			display: flex;
			align-items: center;
			
			.link-dot {
				height: 12.5px;
				width: 12.5px;
				border-radius: 50%;
				margin: 3px;
			}
		
		}
	}
}
.link-dot.connecting {
	animation: blink 1s step-start infinite;
}
@keyframes blink {
	50% {
		opacity: 0;
	}
}
.blue-button {
	background: #294b70;
	color: $darkTextColor;
	font-family: 微軟正黑體, PingFang SC, Microsoft JhengHei;
	padding: 0 16px;
	border: none;
	border-radius: 10px;
	height: 100px;
	min-width: 100px;
	font-size: calc(8px + 0.625rem);
	font-weight: bold;
	display: flex;
	align-items: center;
}

.bluetooth {
	cursor: pointer;
	margin-left: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 9px 16px;
	background: $lightTextColor;
	border-radius: 24px;
	transition: 0.2s;
	color: $darkTextColor;
	@include rwd(1200) {
		padding: 3px 16px 3px 10px;
		margin-left: 10px;
	}
	@include rwd(765) {
		margin-left: 0px;
	}
	
	> img {
		width: 24px;
		height: 24px;
		opacity: 0.7;
		@include rwd(1200) {
			margin-right: 4px;
		}
	}
	
	.bluetooth-box {
		display: flex;
		align-items: center;
		
		@include rwd(1200) {
			flex-direction: column;
		}
	}
	
	::v-deep {
		.v-icon {
			font-size: 35px;
		}
	}
	
	&:active {
		opacity: 0.5;
	}
	
	&-link {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 1rem;
		margin-right: 10px;
		@include rwd(1200) {
			margin-right: 0;
		}
		
		.link-dot {
			height: 12.5px;
			width: 12.5px;
			border-radius: 50%;
			margin-left: 3px;
		}
	}
}

.fullscreen {
	cursor: pointer;
}

.go-front {
	@extend %common-btn;
	color: $darkTextColor;
	background-color: $com2Color;
	
	&:active {
		opacity: 0.3;
	}
}

.logout {
	@extend %common-btn;
	color: #959cb6;
	background: rgba(77, 89, 149, 0.06);
	margin-right: 0;
	
	&:hover {
		color: #fff;
		background-color: #959cb6;
	}
}

.is-mobile {
	left: 0 !important;
	width: 100vw !important;
	max-width: 100vw !important;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
</style>
