<template>
	<div class="searchbar">
		<MyTextField
			:label="label"
			v-model="_value"
			labelPosition="top"
			@onEnter="search"
			:placeholder="placeholder"
		></MyTextField>
		<v-icon class="search-icon" @click="search">mdi-magnify</v-icon>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MySearchBar',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: true,
			type: String,
		},
		label: {
			required: false,
			type: String,
			default: '搜尋你的搜尋',
		},
		placeholder: {
			required: false,
			type: String,
		},
	},
	model: {
		prop: 'value',
		event: 'update',
	},
	// data() {return {}},
	computed: {
		_value: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('update', value)
			},
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		search() {
			let val = this.value
			if (this.value.trim() === '') {
				val = ''
				this.$emit('update', '')
			}
			this.$emit('search', val)
		},
	},
}
</script>
<style lang="scss" scoped>
.searchbar {
	position: relative;
	max-width: 210px;
}
.search-icon {
	position: absolute;
	right: 6px;
	top: 31px;
}
::v-deep {
	.v-input--dense {
		& > .v-input__control {
			& > .v-input__slot {
				margin-bottom: 0;
			}
		}
	}
	.v-text-field__details {
		display: none;
	}
}
</style>
