<template>
	<MyFormRow
		class="custom-form-row"
		:label="label"
		:validate="validate"
		:width="labelWidth"
		:labelAlign="labelAlign"
		:labelPosition="labelPosition"
		:color="color"
	>
		<div class="custom-switch-wrap">
			<v-switch
				class="custom-switch"
				v-model="_value"
				:label="switchLabel"
				:disabled="disabled"
			>
				<template #label>
					<slot name="label">{{ switchLabel }}</slot>
				</template>
			</v-switch>
		</div>
	</MyFormRow>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MySwitch',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: Boolean | Array,
			default: false,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		switchLabel: {
			required: false,
			type: String,
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
		_value: {
			get() {
				return this.value
			},
			set(checked) {
				this.$emit('updateValue', checked)
				this.$emit('onChange', checked)
			},
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.custom-form-row {
	margin-bottom: 3px;
}
.custom-switch-wrap {
	display: flex;
	align-content: flex-start;
	flex-wrap: wrap;
}
.custom-switch {
	margin-top: 3px;
	margin-right: 16px;
}
</style>
