<template>
	<div class="label-select">
		<div class="label-select__label">{{ label }}</div>
		<v-select
			:value="value"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:no-data-text="noDataText"
			:disabled="disabled"
			:rules="rules"
			outlined
			class="label-select__select"
			dense
			single-line
			:clearable="clearable"
			:placeholder="placeholder"
			@change="onChange"
			@click:clear="onClear"
			:menu-props="{ bottom: true, overflowY: true, nudgeTop: '-40px' }"
		></v-select>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyLabelSelect',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: String | Number | Boolean,
		},
		rules: {
			required: false,
			type: Array,
			default: () => [],
		},
		placeholder: {
			required: false,
			type: String,
			default: '',
		},
		itemText: {
			required: false,
			type: String,
			default: 'text',
		},
		itemValue: {
			required: false,
			type: String,
			default: 'value',
		},
		items: {
			required: false,
			type: Array,
			default: () => [],
		},
		noDataText: {
			required: false,
			type: String,
			default: '暫無數據',
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		// 以下皆為 FormRow 參數
		label: {
			required: false,
			type: String,
			default: '名稱',
		},
		labelWidth: {
			required: false,
			type: Number | String,
			default: 'auto',
		},
		labelAlign: {
			required: false,
			type: String,
			default: 'left',
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left',
		},
		color: {
			required: false,
			type: String,
			default: 'dark',
		},
		clearable: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	// data() {return {}},
	computed: {
		validate() {
			return this.rules.length > 0
		},
	},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onChange(val) {
			this.$emit('updateValue', val)
			this.$emit('onChange', val)
		},
		onClear(ev) {
			this.$emit('onClear', ev)
		},
	},
	// methods: {},
}
</script>
<style lang="scss" scoped>
.label-select {
	display: flex;
	align-items: center;
	min-width: calc(180px + 5rem);
	border-radius: 8px;
	border: solid 2px $lineColor;
	height: 48px;
	position: relative;
	@include rwd(776) {
		width: 80vw;
	}
	&__label {
		font-size: 1rem;
		border-right: solid $lineColor;
		color: $lightTextColor;
		white-space: nowrap;
		min-width: 35%;
		text-align: center;
	}
	&__select {
		border: none !important;
		flex: 1;
		max-width: none !important;
		min-width: 65%;

		&::v-deep {
			.v-select__selection {
				font-size: 1rem;
				margin: 0;
				line-height: 1.2rem;
			}
		}
	}
}
</style>
