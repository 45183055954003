import service from './service'
// 錢箱紀錄
export default {
	// 新增
	add: params => service.post(`back/cashDrawerRecord/add`, params),

	// 編輯
	update: params => service.put(`back/cashDrawerRecord/update`, params),

	// 刪除
	remove: params => service.delete(`back/cashDrawerRecord/remove`, { params }),

	// 分頁
	getPage: params => service.post(`back/cashDrawerRecord/getPage`, params),
}
