<template>
	<div class="chart-table" ref="tableRef">
		<div class="chart-table__chart" ref="chartRef">
			<div class="chart-table__chart__title">
				<slot />
			</div>
			<label v-if="loading" class="loading-text">資料載入中...</label>
			<label v-else-if="noSalesData" class="loading-text">無銷售數據</label>
			<Pie
				v-else
				ref="pie"
				:chart-options="chartOptions"
				:chart-data="chartData"
				chart-id="pie-chart"
				dataset-id-key="label"
				css-classes="chart"
			/>
			<div />
		</div>

		<div class="chart-table__table">
			<div class="chart-table__table__search">
				<MyLabelSelect
					class="mr-2"
					v-if="hasCategory"
					label="餐點分類"
					v-model="category"
					:items="categoryList"
					itemValue="id"
					itemText="name"
					@onChange="categoryChange"
				></MyLabelSelect>

				<MyLabelTextField
					class="flex-grow-1"
					label="快速搜尋"
					v-model="searchText"
					type="text"
					:placeholder="'請輸入商品名稱'"
				></MyLabelTextField>
			</div>

			<div class="table">
				<v-data-table
					:headers="headers"
					:items="tableData"
					mobile-breakpoint="0"
					:no-data-text="loading ? '資料載入中...' : '無銷售數據'"
					disable-pagination
					hide-default-footer
					fixed-header
					:item-class="itemClass"
				>
          <template v-slot:header.help="{ header }">
              <span>{{ header.text }}</span>
              <v-tooltip bottom text="text">
                <template v-slot:activator="{ on, props }" >
                  <v-icon v-bind="props" v-on="on">mdi-help-circle</v-icon>
                </template>
                <span>此為銷售數量排行榜，總金額因涉及招待、折扣與口味等變相，不等於數量x單價</span>
              </v-tooltip>
          </template>
					<template v-slot:item.rank="{ item }">
						{{ item.rank }}
					</template>
					<template v-slot:item.name="{ item }">
						{{ item.name }}
					</template>

					<template v-slot:item.totalCount="{ item }">
						{{ $formatDecimal(item.totalCount) }}
					</template>
					<template v-slot:item.price="{ item }">
						{{ item.price }}
					</template>
					<template v-slot:item.totalPrice="{ item }">
						{{ $formatDecimal(item.totalPrice) }}
					</template>
				</v-data-table>
			</div>
		</div>
	</div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	CategoryScale,
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
	name: 'MyChartTable',
	components: {
		Pie,
	},
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
		hasCategory: {
			type: Boolean,
			required: false,
			default: false,
		},
		hasOnceMoney: {
			type: Boolean,
			required: false,
			default: true,
		},
		categoryList: {
			type: Array,
			required: false,
			default: () => [],
		},
		tableData: {
			type: Array,
			required: false,
			default: () => [],
		},
	},

	data() {
		return {
			searchText: '',
			category: null,
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (context) {
								return context.raw.name.map((v, i) =>
									i !== context.raw.name.length - 1 ? ` ${v}\n` : ` ${v}`,
								)
							},
						},
						bodyFont: { weight: 'bold', size: 18 },
					},
					legend: {
						position: 'bottom',
						align: 'start',
						labels: {
							font: { weight: 'bold', size: 16 },
							boxWidth: 16,
							usePointStyle: true,
						},
						fullWidth: false,

						display: false,
					},
				},
				parsing: {
					key: 'value',
				},
			},
		}
	},
	computed: {
		chartData() {
			const labels = []
			let data = []
			if (this.tableData?.length > 0) {
				let rank = this.tableData.filter(v => v.rank < 10 && v.totalCount > 0)
				const elseRank = this.tableData.filter(
					v => v.rank >= 10 || v.totalCount === 0,
				)
				if (rank.length === 0) {
					labels.push('全部')
					data.push({ name: ['目前沒有資料'], value: 0 })
				} else {
					let rankList = rank.reduce((a, b) => {
						const list = a
						if (!list[b.rank]) {
							list[b.rank] = []
						}
						list[b.rank].push(b)
						return list
					}, [])
					let rankListData = []
					Object.keys(rankList).forEach((v, i) => {
						rankListData.push(rankList[v])
					})

					rankListData = rankListData.reduce((a, b) => {
						return [
							...a,
							{
								name: b.map(v =>
									v.name.length > 14 ? v.name.slice(0, 14) + '...' : v.name,
								),
								value: b.reduce((c, d) => c + d.totalCount, 0),
							},
						]
					}, [])
					rankListData.push({
						name: ['其他'],
						value: elseRank?.reduce((c, d) => c + d.totalCount, 0) || 0,
					})

					const totalValue = rankListData.reduce((a, v) => v.value + a, 0)
					let value = 0
					data = rankListData.map((v, i) => {
						if (rankListData.length - 1 === i) {
							return {
								name: v.name.map(
									a =>
										`${a} ${(100 - value).toFixed(
											this.$isInt(100 - value) ? 0 : 1,
										)}%`,
								),
								value: 100 - value,
							}
						} else {
							const person = Number(
								((v.value / totalValue) * 100).toFixed(
									this.$isInt((v.value / totalValue) * 100) ? 0 : 1,
								),
							)
							value += person
							return {
								name: v.name.map(
									a =>
										`${a} ${(person / v.name.length).toFixed(
											this.$isInt(person / v.name.length) ? 0 : 1,
										)}%`,
								),
								value: person,
							}
						}
					})
				}
			} else {
				labels.push('全部')
				data.push({ name: ['全部 0%'], value: 100 })
			}
			return {
				labels: labels,
				datasets: [
					{
						backgroundColor: [
							'#5E69FF',
							'#58BEC3',
							'#EDB477',
							'#CB73AE',
							'#4BBCEF',
							'#FF9B43',
							'#9491EB',
							'#FF6161',
							'#FF6161',
							'#CCCCCC',
							'#FF8467',
						],
						data: data,
					},
				],
			}
		},
		noSalesData() {
			let result = false
			if (this.tableData?.length > 0) {
				let salesData = this.tableData.filter(v => v.totalCount > 0)
				result = salesData.length === 0
			} else {
				result = true
			}
			return result
		},
		headers() {
			const header = [
				{
					text: '排名',
					align: 'center',
					value: 'rank',
					width: '15%',
				},
				{
					text: '商品',
					align: 'left',
					value: 'name',
					sortable: false,
					width: '32.5%',
				},

				{
					text: '數量',
					align: 'center',
					value: 'totalCount',
					width: '17.5%',
				},
				{
					text: '單價',
					align: 'center',
					value: 'price',
					width: '15%',
				},
				{
					text: '總金額',
					align: 'right',
					value: 'totalPrice',
					width: '32.5%',
				},
        {
          text: '',
          align: 'left',
          value: 'help',
          width: '1%',
          sortable: false
        },
			]
			if (!this.hasOnceMoney) {
				header.splice(3, 1)
			}
			return header
		},
	},
	watch: {
		searchText() {
			if (this.searchText?.length > 0) {
				this.$nextTick(() => {
					const trActiveDom =
						this.$refs.tableRef.getElementsByClassName('trActive')

					if (trActiveDom?.length > 0) {
						this.$refs.tableRef
							.getElementsByClassName('v-data-table__wrapper')[0]
							.scrollTo({
								top: trActiveDom[0].offsetTop - 48,
								behavior: 'smooth',
							})
					}
				})
			}
		},
		categoryList() {
			if (this.categoryList?.length > 0) {
				this.category = this.categoryList[0]?.id
			}
		},
	},

	methods: {
		categoryChange() {
			this.$emit('categoryChange', this.category)
		},
		itemClass(item) {
			if (this.searchText?.length > 0) {
				return item?.name?.indexOf(this.searchText) >= 0 ? 'trActive' : ''
			} else {
				return ''
			}
		},
	},
}
</script>
<style scoped lang="scss">
.chart-table {
	display: flex;
	height: 100%;
	overflow: hidden;
	width: 100%;
	&__chart {
		flex: 5;
		padding: 0 32px;
		display: flex;
		flex-direction: column;
		&__title {
			height: 10%;
			padding-bottom: 2%;
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}
		.chart {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15%;
			@include rwd(1000) {
				padding: 0 10%;
			}
			&::v-deep {
				canvas {
					height: 100% !important;
					max-height: 100% !important;
					width: 100% !important;
					max-width: 100% !important;
					border: solid rgba(0, 0, 0, 0);
				}
			}
		}
		.loading-text {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15%;
			font-weight: bold;
			@include rwd(1000) {
				padding: 0 10%;
			}
		}
	}
	&__table {
		flex: 8;
		display: flex;
		flex-direction: column;
		&__search {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 6px;
		}
		&::v-deep {
			.trActive {
				background: rgba($com2Color, 0.1) !important;
			}
		}
	}
	.table {
		flex: 1;
		overflow: hidden;

		&::v-deep {
			.v-data-table {
				overflow: hidden;
				height: 100%;
				.v-data-table__wrapper {
					height: 100%;
				}
			}
		}
	}
	@include rwd(765) {
		flex-direction: column;
		.table {
			overflow: visible;

			&::v-deep {
				.v-data-table__wrapper {
					height: auto !important;
					overflow-y: visible;
				}
			}
		}
		&__table {
			&__search {
				display: flex;
				align-items: center;
				flex-direction: column;

				margin-bottom: 6px;
				> * {
					width: 100%;
					margin-left: 0;
					margin-top: 5px;
				}
			}
		}
		&__chart {
			.chart {
				padding: 0;
				&::v-deep {
					canvas {
						width: 375px !important;
						height: 375px !important;
						max-width: 375px !important;
						max-height: 375px !important;
					}
				}
			}
		}
	}
}
</style>
